import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  list: {
    data: [],
    meta: cloneDeep(initMeta),
  },
  listEvent: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  count: {
    online: 0,
    offline: 0,
  },
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.listEvent = cloneDeep(initEvent)
        draft.listEvent.IsLoading = true
        draft.list = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitSuccess = true
        draft.list.data = response.data
        if (response.meta) {
          draft.list.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.countAction.request):
        draft.count = {
          offline: 0,
          online: 0,
        }
        return draft
      case getType(actions.countAction.success):
        response = action.payload
        draft.count = response.data
        return draft
      case getType(actions.clearEventAction):
        draft.listEvent = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
