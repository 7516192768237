import { combineReducers } from 'redux'
import common from './common/common.reducer'
import userReducer from './user/reducer'
import uploadReducer from './upload/reducer'
import withdrawReducer from './withdraw/reducer'
import withdrawTransactionReducer from './withdraw-transaction/reducer'
import chatReducer from './chat/reducer'
import layoutReducer from './layout/reducer'
import agentReducer from './agent/reducer'
import exampleReducer from './example/example.reducer'
import registerReducer from './register/reducer'
import customerReducer from './customer/reducer'
import geetingMessageReducer from './setting-message/reducer'
import dashboardReducer from './dashboard/reducer'
import chatReportReducer from './chat-report/reducer'
import chaiyoCustomerReducer from './chaiyo-customer/reducer'
import saleTaskReducer from './sale-task/reducer'
import partnerQrReducer from './partner-qr/reducer'
import manageAgentReducer from './manage-agent/reducer'
import reportStandbyReducer from './report-standby/reducer'
import branchTaskReducer from './branch-task/reducer'

const rootReducer = combineReducers({
  common,
  userReducer,
  uploadReducer,
  withdrawReducer,
  withdrawTransactionReducer,
  chatReducer,
  layoutReducer,
  exampleReducer,
  agentReducer,
  registerReducer,
  customerReducer,
  geetingMessageReducer,
  dashboardReducer,
  chatReportReducer,
  chaiyoCustomerReducer,
  saleTaskReducer,
  partnerQrReducer,
  manageAgentReducer,
  reportStandbyReducer,
  branchTaskReducer,
})

export default rootReducer
