import React from 'react'
import { Space } from 'antd'
import { formatDateWithoutTime, getDiffDay, numberWithComma } from 'helpers/format'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { consoleLog } from 'helpers/utils'

type Props = {
  date: string
  direction?: 'vertical' | 'horizontal'
}

const ExpireDate = (props: Props) => {
  const { direction = 'vertical', date } = props
  const remainDay = getDiffDay(date).toFixed(0)
  consoleLog('remainDay', remainDay)
  const isWarning = remainDay <= 3
  let title = 'อีก'
  if (remainDay < 0) {
    title = 'เกิดกำหนดนัดหมาย'
  }

  if (remainDay == 0) {
    title = 'ถึงกำหนดนัดหมายวันนี้'
  }

  const dateContent = (
    <MySpace direction={direction} size="small">
      {formatDateWithoutTime(date)}
      {remainDay == 0 ? (
        <Description>({title})</Description>
      ) : (
        <Description>
          ({title} {numberWithComma(Math.abs(remainDay))} วัน)
        </Description>
      )}
    </MySpace>
  )
  return isWarning ? <Warning>{dateContent}</Warning> : dateContent
}

const MySpace = styled(Space)`
  .ant-space-item {
    margin-bottom: 0px !important;
  }
`

const Warning = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
`

const Description = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
`

export default ExpireDate
