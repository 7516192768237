import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  list: {
    data: [],
    meta: cloneDeep(initMeta),
  },
  listEvent: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  detail: undefined,
  detailEvent: cloneDeep(initEvent),
  partnerData: [],
  partnerCodes: {
    channel_code: [],
    industry_code: [],
  },
  partnerProfile: undefined,
  agencies: [],
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.listEvent = cloneDeep(initEvent)
        draft.listEvent.IsLoading = true
        draft.list = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitSuccess = true
        draft.list.data = response.data
        if (response.meta) {
          draft.list.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getDetailAction.request):
        draft.detailEvent = cloneDeep(initEvent)
        draft.detailEvent.IsLoading = true
        draft.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitSuccess = true
        draft.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.createAction.request):
      case getType(actions.updateQrAgentStatusAction.request):
      case getType(actions.updateAction.request):
      case getType(actions.updateQrAgentImageAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updateQrAgentStatusAction.success):
      case getType(actions.updateAction.success):
      case getType(actions.createAction.success):
      case getType(actions.updateQrAgentImageAction.success):
        response = action.payload
        if (response?.status.code === 200) {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = undefined
          draft.actionEvent.SubmitSuccess = true
          draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        } else {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = response?.message
        }

        return draft
      case getType(actions.updateQrAgentStatusAction.failure):
      case getType(actions.updateAction.failure):
      case getType(actions.createAction.failure):
      case getType(actions.updateQrAgentImageAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getAgentStructerAction.request):
        draft.partnerData = []
        return draft
      case getType(actions.getAgentStructerAction.success):
        response = action.payload
        draft.partnerData = response.data
        return draft
      case getType(actions.getAgentCodeAction.request):
        draft.partnerCodes = {
          channel_code: [],
          industry_code: [],
        }
        return draft
      case getType(actions.getAgentCodeAction.success):
        response = action.payload
        draft.partnerCodes = response.data
        return draft
      case getType(actions.getAgentInfoAction.request):
        draft.partnerInfo = undefined
        return draft
      case getType(actions.getAgentInfoAction.success):
        response = action.payload
        draft.partnerInfo = response.data
        return draft

      case getType(actions.getAgentProfileAction.request):
        draft.detailEvent = cloneDeep(initEvent)
        draft.detailEvent.IsLoading = true
        draft.partnerProfile = undefined
        return draft
      case getType(actions.getAgentProfileAction.success):
        response = action.payload
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitSuccess = true
        draft.partnerProfile = response.data
        return draft
      case getType(actions.getAgentProfileAction.failure):
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getAgenciesAction.request):
        draft.agencies = []
        return draft
      case getType(actions.getAgenciesAction.success):
        response = action.payload
        draft.agencies = response.data
        return draft
      case getType(actions.clearEventAction):
        draft.listEvent = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
