import React from 'react'
import { Tag, Tooltip } from 'antd'

type Props = {
  text: string
  isPass?: boolean
  color?: string
  reason?: string
}

const StatusTag = (props: Props) => {
  const { text, isPass = true, color = '', reason = '' } = props
  let tagColor = ''
  if (color) {
    tagColor = color
  } else {
    tagColor = isPass ? 'green' : 'red'
  }

  if (reason && reason !== '') {
    return (
      <Tooltip title={`เหตุผล: ${reason}`} placement="top">
        <Tag color={tagColor} style={{ cursor: 'pointer' }}>
          {text}
        </Tag>
      </Tooltip>
    )
  }

  return <Tag color={tagColor}>{text}</Tag>
}

export default StatusTag
