import withAPIAction from 'helpers/withAPIAction'
import {
  ListReq,
  DetailReq,
  UpdateReq,
  UpdateStatusReq,
  UpdateQrAgentImageReq,
  CreateReq,
  AgentStructureReq,
  AgentCodeReq,
  AgentInfoReq,
  ProfileReq,
  AgenciesReq,
} from './types'
import {
  getListAction,
  updateAction,
  getDetailAction,
  updateQrAgentStatusAction,
  updateQrAgentImageAction,
  createAction,
  getAgentStructerAction,
  getAgentCodeAction,
  getAgentInfoAction,
  getAgentProfileAction,
  getAgenciesAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const createQrAgentAPI = (data: CreateReq, token: string) => {
  return withAPIAction<CreateReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.createQrAgent,
    data,
    token,
  })(createAction)
}

export const getAgentStructerAPI = (data: AgentStructureReq, token: string) => {
  return withAPIAction<AgentStructureReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getQrAgentStructers}?${getRequestString(data)}`,
    data,
    token,
  })(getAgentStructerAction)
}

export const getAgentCodesAPI = (data: AgentCodeReq, token: string) => {
  return withAPIAction<AgentCodeReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getQrAgentCodes}?${getRequestString(data)}`,
    data,
    token,
  })(getAgentCodeAction)
}

export const getAgentInfoAPI = (data: AgentInfoReq, token: string) => {
  const endPoint = APIPath.getQrAgentInfoByID.replace(':id', data.id.toString())
  return withAPIAction<AgentInfoReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getAgentInfoAction)
}

export const getAgentProfileAPI = (data: ProfileReq, token: string) => {
  return withAPIAction<ProfileReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getQrAgentProfile,
    data,
    token,
  })(getAgentProfileAction)
}

export const getQrAgentListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getQrAgentList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getQrAgentDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.getQrAgentByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateQrAgentAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateQrAgent.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateQrAgentStatusAPI = (data: UpdateStatusReq, token: string) => {
  const endPoint = APIPath.updateStatusQrAgent.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateQrAgentStatusAction)
}

export const getAgenciesListAPI = (data: AgenciesReq, token: string) => {
  return withAPIAction<AgenciesReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getAgentciesList}?${getRequestString(data)}`,
    data,
    token,
  })(getAgenciesAction)
}
