export enum APIPath {
  example = 'v1/example',
  //for user authentication
  login = '/backend/api/v1/auth/login',
  getUserProfile = '/backend/api/v1/user/profile',
  verifyToken = '/api/v1/user/token/:token',
  updatePassword = '/api/v1/user/reset-password/:token',
  getAgentProfile = '/api/v1/user/agent/profile/:uuid',

  resetPassword = '/api/v1/user/forgot-password',

  staffMerchants = '/backend/api/v1/auth/store/staff/merchants',
  registerNewAccount = '/merchant-service/merchant/register-user',
  staffLogin = '/backend/api/v1/auth/store/staff/login',
  staffPermission = '/backend/api/v1/auth/store/staff/permissions',
  merchantPermission = '/backend/api/v1/auth/merchant/permissions',

  //upload
  upload = '/api/v1/cdn/upload',

  //merchants
  merchantsDropdown = 'merchant-service/merchant/get-merchants-dropdown',

  //order
  getOrderList = 'v1/escrow/orders',
  countNewOrder = 'v1/escrow/orders/count',
  updateOrderStatus = 'order-service/order/update-product-status',
  createBestTracking = 'geo/best/pdf',
  getOrderByID = 'order-service/order/escrow-find-order',
  getOrderLogs = 'order-service/order/OrderLogs',

  //dashboard
  paymentDashboard = '/backend/api/v1/order/payment/dashboard/escrow/payment',
  paymentStatDashboard = '/backend/api/v1/order/payment/dashboard/escrow/stat',
  topSaleDashboard = '/backend/api/v1/order/payment/dashboard/escrow/topsale',
  platformDashboard = '/backend/api/v1/order/payment/dashboard/escrow/platform',
  actualSaleDashboard = '/api/actual_sales_performance',
  productStockDashboard = '/backend/api/v1/order/payment/dashboard/escrow/stock',

  //order-verify
  getOrderWaitingList = 'api/payment-trans-waiting',
  getOrderVeirfyedList = 'api/payment-verifieds',
  getOrderVerifyByID = 'api/payment-trans-detail',
  countVerifyOrder = 'api/payment-trans-count',
  uploadPaymentTrans = 'api/payment-trans-waiting-by-csv',
  uploadDeliveryTrans = 'api/delivery-trans-by-csv',
  updateVerifyOrderStatus = 'api/payment-verified',
  rejectOrderStatus = 'api/payment-verified-reject',

  //withdraw
  getWithdrawList = 'api/withdraw-list',
  updateWithdrawRemain = 'api/withdraw-remain',
  getWithdrawByStatus = 'api/withdraw-by-status',
  updateWithdrawStatus = 'api/withdraw-status',
  getWithdrawLogs = 'api/withdraw-log',

  //fee-setting
  getFeeList = 'api/fee-list',
  getFeeDetail = 'api/fee-detail',
  createFeeSetting = 'api/fee-add',
  updateeFeeSetting = 'api/fee-update',
  merchantsFeeList = 'api/merchants',
  merchantsSelectedList = 'api/merchant-fee-setting-list',
  updateMerchantsSelectedList = 'api/merchant-fee-setting-add',

  //merchant summary
  getMerchantSummaryList = 'api/merchant-summary',

  //merchants
  merchantsList = '/merchant-service/merchant/merchants',
  approveMerchants = '/merchant-service/merchant/approve-merchant',
  rejectMerchants = '/merchant-service/merchant/reject-merchant',
  updateMerchants = '/merchant-service/merchant/update-merchant',
  getMerchantsByID = '/merchant-service/merchant/find-merchant',

  //register
  register = '/api/v1/agent/register',
  getAddress = '/api/v1/agent/address/postcode/:postcode',

  //agent
  getAgentList = '/backend/api/v1/agent/list',
  getAgentByID = '/backend/api/v1/agent/:id',
  updateAgent = '/backend/api/v1/agent/:id',
  updateStatusAgent = '/backend/api/v1/agent/status/:id',
  updateAgentImage = '/backend/api/v1/user/team',

  // opd customer
  getCustomerByUUID = '/backend/api/v1/customer/insure/:uuid',
  updateCustomerInfo = '/backend/api/v1/customer/insure/:uuid',
  updateCustomerSocketInfo = '/agentFriends/username',
  customerTaskList = '/backend/api/v1/task/insure/list',
  customerTaskByID = '/backend/api/v1/task/insure/:id',
  updateCustomerTask = '/backend/api/v1/task/insure/:id',
  updateCustomerTaskStatus = '/backend/api/v1/task/insure/status/:id',
  countCustomer = '/backend/api/v1/task/insure/count',

  //chaiyo customer
  getChaiyoCustomerByUUID = '/backend/api/v1/customer/car-loan/:uuid',
  updateChaiyoCustomerInfo = '/backend/api/v1/customer/car-loan/:uuid',
  updateChaiyoCustomerSocketInfo = '/agentFriends/username',
  chaiyoCustomerTaskList = '/backend/api/v1/task/car-loan/list',
  chaiyoCustomerTaskByID = '/backend/api/v1/task/car-loan/:id',
  updateChaiyoCustomerTask = '/backend/api/v1/task/car-loan/:id',
  updateChaiyoCustomerTaskStatus = '/backend/api/v1/task/car-loan/status/:id',
  agentUpdateToBranch = '/backend/api/v1/task/car-loan/status/:id',
  countChaiyoCustomer = '/backend/api/v1/task/car-loan/count',
  getBrandList = '/backend/api/v1/redbook/brands',
  getBrandBySlugList = '/backend/api/v1/redbook/brand/:slug',
  getTaskLogs = '/backend/api/v1/task/log/:id',
  updateChatTask = '/chatMessage/task',
  updateChatTaskReply = '/chatMessage/branch-reply',

  //geeting message
  getGeetingMessage = '/backend/api/v1/geeting-message/list',
  updateGeetingMessage = '/backend/api/v1/geeting-message/save',
  getTemplateMessage = '/api/v1/geeting-message/:uuid/template',
  getEndMessage = '/api/v1/geeting-message/:uuid/end',

  //dashboard
  getCaseDashboard = '/backend/api/v1/dashboard/case-aggregate',
  getTeamCaseDashboard = '/backend/api/v1/dashboard/case-team-aggregate',

  //chat-report
  getConversationReport = '/backend/api/v1/report/conversation',
  exportConversationReport = '/backend/api/v1/report/conversation/export',
  getBookLog = '/agentFriends/bookLog',
  getBookCurrent = '/agentFriends/bookCurrent',

  //standby report
  getStandbyReportList = '/backend/api/v1/user-logs/list',
  exportStandbyReportList = '/backend/api/v1/user-logs/export',
  countStandbyReport = '/backend/api/v1/user-logs/count',

  //sale task
  //all
  countAllTab = '/backend/api/v1/sale/task/car-loan/count',
  getAllCustomerTaskList = '/backend/api/v1/sale/task/car-loan/list',
  allCarloanByID = '/backend/api/v1/sale/task/car-loan/:id',
  saleAssignTask = '/backend/api/v1/sale/task/car-loan/assign/:id',

  //me
  countMeTab = '/backend/api/v1/sale/me/task/car-loan/count',
  getMeCustomerTaskList = '/backend/api/v1/sale/me/task/car-loan/list',
  meCarloanByID = '/backend/api/v1/sale/me/task/car-loan/:id',
  saleUpdateTaskStatus = '/backend/api/v1/sale/me/task/car-loan/status/:id',
  saleUpdateTask = '/backend/api/v1/sale/me/task/car-loan/:id',

  //chat-sale
  getChatSaleCustomerTaskList = '/backend/api/v1/chat-sale/list',
  countChatSaleTab = '/backend/api/v1/chat-sale/count',
  chatSaleCarloanByID = '/backend/api/v1/chat-sale/:id',
  chatSaleUpdateTaskStatus = '/backend/api/v1/chat-sale/status/:id',

  getBranchList = '/backend/api/v1/branch/list',
  getReasonList = '/backend/api/v1/reason/list',
  getPostcodeList = '/backend/api/v1/branch/car-loan/postcodes',
  getDeliveryStaffList = '/backend/api/v1/branch/car-loan/postcode/:id',
  getProvinceList = '/backend/api/v1/address/province-list',

  //qr-partner
  //agent
  createQrPartner = '/backend/api/v1/user-partner/create',
  getPartnerStructers = '/backend/api/v1/user-partner/structures',
  getPartnerCodes = '/backend/api/v1/reason/codes',
  getQrPartnerList = '/backend/api/v1/user-partner/list',
  getQrPartnerByID = '/backend/api/v1/user-partner/:id',
  getQrPartnerInfoByID = '/backend/api/v1/user-partner/info/:id',
  updateQrPartner = '/backend/api/v1/user-partner/:id',
  updateStatusQrPartner = '/backend/api/v1/user-partner/status/:id',
  updateQrPartnerImage = '/backend/api/v1/user/team',
  getQrPartnerProfile = '/backend/api/v1/user-partner/profile',
  getQrPartnerScanQrDashbaord = '/agentFriends/scanQr',
  getPartnerQrCaseDashboard = '/backend/api/v1/dashboard/case-aggregate-partner',

  //manage agent
  createQrAgent = '/backend/api/v1/user-agent/create',
  getQrAgentStructers = '/backend/api/v1/user-agent/structures',
  getQrAgentCodes = '/backend/api/v1/reason/codes',
  getQrAgentList = '/backend/api/v1/user-agent/list',
  getQrAgentByID = '/backend/api/v1/user-agent/:id',
  getQrAgentInfoByID = '/backend/api/v1/user-agent/info/:id',
  updateQrAgent = '/backend/api/v1/user-agent/:id',
  updateStatusQrAgent = '/backend/api/v1/user-agent/status/:id',
  getQrAgentProfile = '/backend/api/v1/user-agent/profile',
  getAgentciesList = '/backend/api/v1/user-agent/agencies',

  //branch
  branchCountTab = '/backend/api/v1/chat-sale/count',
  getBranchTaskList = '/backend/api/v1/branch/task/list',
  getBranchTaskByID = '/backend/api/v1/branch/task/detail',
  branchUpdateTaskStatus = '/backend/api/v1/branch/task/update',
  branchUpdateTask = '/backend/api/v1/branch/task/update',
  getBranchTaskLogs = '/backend/api/v1/branch/task/log/:id',
  getBranchReasonList = '/backend/api/v1/branch/task/reason/list',
}
