import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import {
  ListData,
  Data,
  PartnerStructureData,
  PartnerCodeData,
  PartnerInfoData,
  ProfileData,
  ScanQrDashbaordData,
  PartnerQrCaseData,
} from './types'

export const selectListEvent = (state: RootState): Event => state.partnerQrReducer.listEvent

export const selectList = (state: RootState): ListData => state.partnerQrReducer.list

export const selectActionEvent = (state: RootState): Event => state.partnerQrReducer.actionEvent

export const selectDetail = (state: RootState): Data | undefined => state.partnerQrReducer.detail

export const selectDetailEvent = (state: RootState): Event => state.partnerQrReducer.detailEvent

export const selectPartnerStructer = (state: RootState): PartnerStructureData[] =>
  state.partnerQrReducer.partnerData

export const selectPartnerCode = (state: RootState): PartnerCodeData => state.partnerQrReducer.partnerCodes

export const selectPartnerInfo = (state: RootState): PartnerInfoData | undefined =>
  state.partnerQrReducer.partnerInfo

export const selectPartnerProfile = (state: RootState): ProfileData | undefined =>
  state.partnerQrReducer.partnerProfile

export const selectScanQrcodeDashboardEvent = (state: RootState): Event =>
  state.partnerQrReducer.scanQrDashboard.event

export const selectScanQrcodeDashboardData = (state: RootState): ScanQrDashbaordData | undefined =>
  state.partnerQrReducer.scanQrDashboard.data

export const selectPartnetQrCaseDashboardData = (state: RootState): PartnerQrCaseData | undefined =>
  state.partnerQrReducer.caseDashboard.data

export const selectPartnetQrDashboardEvent = (state: RootState): Event =>
  state.partnerQrReducer.caseDashboard.event
