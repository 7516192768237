import { put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as chatAction from '../chat/actions'
import { updateChatTaskAPI, updateChatTaskReplyAPI, updateCustomerSocketAPI } from './api'
import { ChatTaskReq, ChatTaskReplyReq } from './types'
import { CustomerStatus, SaleTaskStatus } from 'helpers/constanst'
import { consoleLog } from 'helpers/utils'

let lastPayload = undefined as any

export function* updateFriendName(action: any) {
  try {
    const name = action?.payload?.data?.first_name + ' ' + action?.payload?.data?.last_name
    const uuid = action?.payload?.data?.uuid || ''
    yield put(chatAction.updateFriendName({ name: name, uuid: uuid }))
    yield put(
      updateCustomerSocketAPI({
        username: name,
        uuid: uuid,
      }),
    )
  } catch (e) {
    consoleLog(e)
  }
}

export function* updateLastPayload(action: any) {
  try {
    lastPayload = action?.payload
  } catch (e) {
    consoleLog(e)
  }
}

export function* updateChatTask(action: any) {
  try {
    const amsCaseNo = action?.payload?.data?.task_latest?.ams_case_no || ''
    if (lastPayload?.status !== CustomerStatus.OPEN || !amsCaseNo) return
    const req: ChatTaskReq = {
      taskID: amsCaseNo,
      toUser: lastPayload?.toUser,
      fromUser: lastPayload?.fromUser,
    }
    yield put(updateChatTaskAPI(req))
  } catch (e) {
    consoleLog(e)
  }
}

export function* updateChatTaskReply(action: any) {
  try {
    const amsCaseNo = action?.payload?.data?.ams_case_no || ''
    //if (action?.payload?.data?.status !== SaleTaskStatus.TOBRANCH || !amsCaseNo) return
    if (!amsCaseNo) return
    const req: ChatTaskReplyReq = {
      taskID: amsCaseNo,
    }
    yield put(updateChatTaskReplyAPI(req))
  } catch (e) {
    consoleLog(e)
  }
}

function* userSaga() {
  yield takeEvery(getType(actions.updateCustomerAction.success), updateFriendName)
  yield takeEvery(getType(actions.updateCustomerAction.request), updateLastPayload)
  yield takeEvery(getType(actions.updateCustomerAction.success), updateChatTask)
  yield takeEvery(getType(actions.updateToBranchAction.success), updateChatTaskReply)
}

export default userSaga
