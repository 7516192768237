import React from 'react'
import { Alert, Space } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { WarningMessage } from 'Store/chaiyo-customer/types'

type Props = {
  warningMessages: WarningMessage[]
}

const WarningTask = (props: Props) => {
  const { warningMessages } = props

  return (
    <Alert
      message={
        <Space direction="vertical">
          <Title>แจ้งเคสซ้ำ {warningMessages.length} รายการ</Title>
          {warningMessages &&
            warningMessages.map((item, index) => {
              return (
                <span key={index}>
                  เบอร์โทรศัพท์ {item.mobile} , ทะเบียน {item.car_license_plate}
                </span>
              )
            })}
          <span>ดังกล่าว ของลูกค้าอยู่ระหว่างการยื่นขอสินเชื่อ</span>
        </Space>
      }
      type="warning"
    />
  )
}

const Title = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

export default WarningTask
