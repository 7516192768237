import React from 'react'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'
import Title from 'Components/text/Title'
import Text from 'Components/text/Text'
import { MyTheme } from 'Components/common/Theme'
import { PrimaryButton } from 'Components/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface Props {
  isShow: boolean
  handleOk?: () => void
  handleCancel?: () => void
  title?: string
  child: React.ReactNode
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  loading?: boolean
  hasCancel?: boolean
  hasConfirm?: boolean
  width?: string
}

const ConfirmWithChild = (props: Props) => {
  const {
    isShow = false,
    handleOk = () => {},
    handleCancel = () => {},
    title = 'ยืนยันการลบ',
    child,
    confirmBtnTxt = 'ยืนยัน',
    cancelBtnTxt = 'ยกเลิก',
    loading = false,
    hasCancel = true,
    hasConfirm = true,
    width = '520px',
  } = props

  const breakpoint = useBreakpoint()
  const xl = breakpoint.xl || false

  const footer = (
    <FooterStyled>
      <Row justify="center" gutter={[16, 16]}>
        {hasCancel && (
          <Col>
            <PrimaryButton text={cancelBtnTxt} btnType="Cancel" onClick={handleCancel} />
          </Col>
        )}
        {hasConfirm && (
          <Col>
            <PrimaryButton text={confirmBtnTxt} onClick={handleOk} loading={loading} />
          </Col>
        )}
      </Row>
    </FooterStyled>
  )

  return (
    <ModalStyled
      visible={isShow}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={(hasCancel || hasConfirm) && footer}
      centered
      width={xl ? width : '100%'}
    >
      <ContentWrapper>
        <Title>{title}</Title>
        <div>{child}</div>
      </ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const TextStyled = styled(Text)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
  .ant-col {
    width: 150px;
  }
`

export default ConfirmWithChild
