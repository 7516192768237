import React from 'react'
import { ConfirmWithChild } from 'Components/modal'
import { AgentQrCode } from 'Components/utilities'
import { appConfig } from 'config'

type Props = {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  activeUser: any
}

const QrCodeModal: React.FunctionComponent<Props> = props => {
  const { onCloseDrawer, visible, activeUser } = props

  const Content = (
    <AgentQrCode
      title={activeUser?.userSocket?.agentQr}
      uuid={activeUser?.userSocket?.agentID}
      agentType={''}
      width={200}
      domain={`https://${appConfig.chaiyoHostname}`}
      userGroup={'chaiyo'}
      agentCode={activeUser?.userSocket?.agentQr}
    />
  )

  return (
    <ConfirmWithChild
      title={`${activeUser?.userSocket?.agentQr}`}
      isShow={visible}
      handleOk={() => {}}
      handleCancel={onCloseDrawer}
      child={Content}
      confirmBtnTxt="ยืนยัน"
      hasCancel={false}
      hasConfirm={false}
    />
  )
}

export default QrCodeModal
