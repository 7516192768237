import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import {
  currentDate,
  formatDateWithoutTime,
  getLast1Month,
  numberWithComma,
  toDateQuery,
} from 'helpers/format'
import { cloneDeep } from 'lodash'
import { Card, Col, Divider, Row, Space, Statistic, Tag } from 'antd'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'
import { CheckOutlined, PlusOutlined, QrcodeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { RangeDateItem } from 'Components/form'
import { LoadingView } from 'Components/modal'
import { UserType } from 'helpers/constanst'
import {
  getPartnerProfileAPI,
  getPartnerQrCaseDashboardtAPI,
  getQrPartnerScanQrDashboardAPI,
} from 'Store/partner-qr/api'
import {
  CaseData,
  CountScanQrData,
  PartnerQrCaseDashbaordReq,
  ScanQrDashbaordReq,
} from 'Store/partner-qr/types'
import {
  selectPartnerProfile,
  selectPartnetQrCaseDashboardData,
  selectPartnetQrDashboardEvent,
  selectScanQrcodeDashboardData,
  selectScanQrcodeDashboardEvent,
} from 'Store/partner-qr/selector'

type Props = {} & RouteComponentProps & ReduxProps

const Dashboard: React.FunctionComponent<Props> = props => {
  const { userProfile, loginData, caseData, caseEvent, scanQrEvent, scanQrData, profileFullInfo } = props

  const initFilter = {
    start_date: getLast1Month(),
    end_date: currentDate(),
    qr_start_date: getLast1Month(),
    qr_end_date: currentDate(),
  }

  const [filter, setFilter] = useState(cloneDeep(initFilter))
  // const [showDrawer, setShowDrawer] = useState(false)
  const dispatch = useDispatch()

  const onGetPartnerCaseDashboard = (): void => {
    const req: PartnerQrCaseDashbaordReq = {
      start_date: toDateQuery(filter.start_date),
      end_date: toDateQuery(filter.end_date),
    }
    dispatch(getPartnerQrCaseDashboardtAPI(req, loginData?.access_token || ''))
  }

  const onGetScanQrDashboard = (): void => {
    const req: ScanQrDashbaordReq = {
      agentID: userProfile?.uuid || '',
      startDt: toDateQuery(filter.qr_start_date),
      endDt: toDateQuery(filter.qr_end_date),
    }
    dispatch(getQrPartnerScanQrDashboardAPI(req, loginData?.access_token || ''))
  }

  const onGetProfileInfo = (): void => {
    dispatch(getPartnerProfileAPI({}, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (loginData?.access_token) {
      onGetPartnerCaseDashboard()
    }
  }, [filter.end_date])

  useEffect(() => {
    if (loginData?.access_token) {
      onGetScanQrDashboard()
    }
  }, [filter.qr_end_date])

  useEffect(() => {
    if (loginData?.access_token) {
      onGetProfileInfo()
    }
  }, [])

  const handleChangeDate = (statDate: string, endDate: string) => {
    setFilter({
      ...filter,
      start_date: statDate,
      end_date: endDate,
    })
  }

  const handleChangeQrDate = (statDate: string, endDate: string) => {
    setFilter({
      ...filter,
      qr_start_date: statDate,
      qr_end_date: endDate,
    })
  }

  const boxItem = (title: string, value: any, icon: any) => {
    return (
      <Center>
        <Space direction="vertical" size="small">
          <Title>{title}</Title>
          <Value>{numberWithComma(value)} ครั้ง</Value>
        </Space>
      </Center>
    )
  }

  const summaryBox = () => {
    return (
      <BoxWrapper>
        <Row gutter={[4, 24]} justify="space-around" align="top">
          <Col md={6} xs={24}>
            {boxItem(
              'จำนวนผู้สแกน QR Code เข้ามาทั้งหมด',
              scanQrData?.all?.countAll || 0,
              <QrcodeOutlined style={{ fontSize: '30px', color: theme.colors.primary }} />,
            )}
          </Col>
          <Col md={1} xs={0}>
            <Divider type="vertical" style={{ height: '80px' }} />
          </Col>
          <Col md={6} xs={24}>
            {boxItem(
              'จำนวนผู้สแกน QR Code  ของฉัน',
              scanQrData?.self?.countAll || 0,
              <QrcodeOutlined style={{ fontSize: '30px', color: theme.colors.primary }} />,
            )}
          </Col>
          <Col md={1} xs={0}>
            <Divider type="vertical" style={{ height: '80px' }} />
          </Col>
          <Col md={6} xs={24}>
            {boxItem(
              'จำนวนผู้สแกน QR Code ภายใต้โครงสร้างของฉัน',
              scanQrData?.team?.countAll || 0,
              <QrcodeOutlined style={{ fontSize: '30px', color: theme.colors.primary }} />,
            )}
          </Col>
        </Row>
      </BoxWrapper>
    )
  }

  const chatSummaryBox = () => {
    return (
      <Row gutter={[4, 24]} justify="space-around" align="top">
        <Col md={8} xs={24}>
          <BoxWrapper style={{ backgroundColor: '#E7E7E7' }}>
            <Row align="middle" gutter={[8, 16]}>
              <Col span={24}>
                ภาพรวมการสนทนาทั้งหมด <SmallSecondTitle>(ของฉัน+ภายใต้โครงสร้างของฉัน)</SmallSecondTitle>
              </Col>
              <Col md={24} xs={24}>
                {chatSummaryTask(scanQrData?.all, true)}
              </Col>
            </Row>
          </BoxWrapper>
        </Col>
        <Col md={8} xs={24}>
          <BoxWrapper style={{ backgroundColor: '#E0ECDE' }}>
            <Row align="middle" gutter={[8, 16]}>
              <Col span={24}>ภาพรวมการสนทนาของฉัน</Col>
              <Col md={24} xs={24}>
                {chatSummaryTask(scanQrData?.self)}
              </Col>
            </Row>
          </BoxWrapper>
        </Col>
        <Col md={8} xs={24}>
          <BoxWrapper style={{ backgroundColor: '#F3DBCF' }}>
            <Row align="middle" gutter={[8, 16]}>
              <Col span={24}>ภาพรวมการสนทนาภายใต้โครงสร้างของฉัน</Col>
              <Col md={24} xs={24}>
                {chatSummaryTask(scanQrData?.team)}
              </Col>
            </Row>
          </BoxWrapper>
        </Col>
      </Row>
    )
  }

  const taskItem = (title: string, value: number, color: string = '#3f8600', suffix: string = 'งาน') => {
    return (
      <Card>
        <Statistic
          title={title}
          value={value}
          precision={0}
          valueStyle={{ color: color }}
          //prefix={icon}
          suffix={<Title style={{ color: color }}>{suffix}</Title>}
        />
      </Card>
    )
  }

  const chatSummaryTask = (data: CountScanQrData | undefined, showAll: boolean = false) => {
    return (
      <Row gutter={[8, 8]} align="top">
        <Col md={12} xs={12}>
          {taskItem(
            `ปิดเคส${showAll ? 'ทั้งหมด' : ''}`,
            data?.countEndCase || 0,
            theme.colors.primary,
            'Chat',
          )}
        </Col>
        <Col md={12} xs={12}>
          {taskItem(
            `อยู่ระหว่างการสนทนา${showAll ? 'ทั้งหมด' : ''}`,
            data?.countAssigned || 0,
            theme.colors.primary,
            'Chat',
          )}
        </Col>
      </Row>
    )
  }

  const summaryTask = (data: CaseData | undefined) => {
    const open = data?.task?.open || 0
    const onProcess = (data?.sale?.to_branch || 0) + (data?.sale?.to_delivery || 0)
    const book = data?.sale?.close || 0
    const cancel = (data?.sale?.cancel || 0) + (data?.sale?.reject || 0)
    const all = open + onProcess + book + cancel
    return (
      <Row gutter={[32, 8]}>
        <Col md={10} xs={12}>
          <Row gutter={[8, 8]} align="top">
            <Col span={24}>
              <SecondTitle>ภาพรวมจำนวนเคส</SecondTitle>
            </Col>
            <Col md={12} xs={24}>
              {taskItem('จำนวนเปิดเคสทั้งหมด', all, theme.colors.primary)}
            </Col>
            <Col md={12} xs={24}>
              {taskItem('เปิดเคส (ยังไม่ส่งไปสาขา)', open, '#096dd9')}
            </Col>
          </Row>
        </Col>
        <Col md={14} xs={12}>
          <Row gutter={[8, 8]} align="top">
            <Col span={24}>
              <SecondTitle>เปิดเคสไปยังสาขา</SecondTitle>
            </Col>
            <Col md={8} xs={24}>
              {taskItem('อยู่ระหว่างติดตาม', onProcess, '#08979c')}
            </Col>
            <Col md={8} xs={24}>
              {taskItem('Booked', book, 'green')}
            </Col>
            <Col md={8} xs={24}>
              {taskItem('ยกเลิก', cancel, 'red')}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const mySummaryBox = () => {
    return (
      <>
        <p>
          <UserOutlined /> ภาพรวมจำนวนเคสของฉัน
        </p>
        <BoxWrapper>
          <Row align="middle" gutter={[8, 16]}>
            <Col md={24} xs={24}>
              {summaryTask(caseData?.me)}
            </Col>
          </Row>
        </BoxWrapper>
      </>
    )
  }

  const teamSummaryBox = () => {
    return (
      <>
        <p>
          <TeamOutlined /> ภาพรวมจำนวนเคสภายใต้โครงสร้างของฉัน{' '}
          <Tag color={theme.colors.primary}>{profileFullInfo?.qr_name || ''}</Tag>
        </p>
        <BoxWrapper>
          <Row align="top" gutter={[8, 16]}>
            <Col md={24} xs={24}>
              {summaryTask(caseData?.team)}
            </Col>
          </Row>
        </BoxWrapper>
      </>
    )
  }

  //const SummaryTeamDrawer = <SummaryTeam visible={showDrawer} onClose={() => setShowDrawer(false)} />

  if (userProfile?.user_type === UserType.ADMIN) {
    return <HomePageLayout selected="dashboard" title={`Dashboard`} label={'comming soon'}></HomePageLayout>
  }

  if (userProfile?.user_type === UserType.SALE) {
    return <HomePageLayout selected="dashboard" title={`Dashboard`} label={'comming soon'}></HomePageLayout>
  }

  return (
    <HomePageLayout selected="dashboard" title={`Dashboard`} label={''}>
      <LoadingView isShow={caseEvent.IsLoading || scanQrEvent.IsLoading} />
      {/* {SummaryTeamDrawer} */}
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <TitleBox>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col>
                <Space wrap={true}>
                  ภาพรวมจำนวนผู้สแกน QR Code เข้ามา
                  <Title>{`(ประจำวันที่ ${formatDateWithoutTime(
                    filter.qr_start_date,
                  )} - ${formatDateWithoutTime(filter.qr_end_date)})`}</Title>
                </Space>
              </Col>
              <Col md={8} xs={24}>
                <RangeDateItem
                  placeHolder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                  onChange={handleChangeQrDate}
                  startDate={filter.qr_start_date}
                  endDate={filter.qr_end_date}
                  allowClear={false}
                />
              </Col>
            </Row>
          </TitleBox>
          {summaryBox()}
          <br />
          {chatSummaryBox()}
        </Col>
        <Col span={24}>
          <TitleBox>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col>
                <Space wrap={true}>
                  สรุปภาพรวมจำนวนเคส
                  <Title>{`(ประจำวันที่ ${formatDateWithoutTime(filter.start_date)} - ${formatDateWithoutTime(
                    filter.end_date,
                  )})`}</Title>
                </Space>
              </Col>
              <Col md={8} xs={24}>
                <RangeDateItem
                  placeHolder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                  onChange={handleChangeDate}
                  startDate={filter.start_date}
                  endDate={filter.end_date}
                  allowClear={false}
                />
              </Col>
            </Row>
          </TitleBox>
          <Row gutter={[16, 16]} justify="space-between" align="top">
            <Col md={24} xs={24}>
              {mySummaryBox()}
            </Col>
            <Col md={24} xs={24}>
              {teamSummaryBox()}
            </Col>
          </Row>
        </Col>
      </Row>
    </HomePageLayout>
  )
}

const BoxWrapper = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  padding: 20px 10px;
  border-radius: 5px;
  .ant-statistic-title {
    font-size: 12px;
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  }
  .ant-card-body {
    padding: 12px;
    text-align: center;
  }
`

const TitleBox = styled.div`
  margin: 10px 0;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
`

const Value = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
`

const Center = styled.div`
  text-align: center;
`

const SecondTitle = styled.div`
  font-size: 14px;
`

const SmallSecondTitle = styled.span`
  font-size: 12px;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

// const ValueBig = styled.div`
//   color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
//   font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h2};
//   text-align: center;
// `

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
    caseData: selectPartnetQrCaseDashboardData(state),
    caseEvent: selectPartnetQrDashboardEvent(state),
    scanQrEvent: selectScanQrcodeDashboardEvent(state),
    scanQrData: selectScanQrcodeDashboardData(state),
    profileFullInfo: selectPartnerProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Dashboard)
