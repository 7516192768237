import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'
import { toDateMoment, toMoment } from 'helpers/format'
import locale from 'antd/es/date-picker/locale/th_TH'
import moment from 'moment'

const { RangePicker }: any = DatePicker

interface MenuProps {
  name: string
  value: string | number
}

type Props = {
  placeHolder?: [string, string]
  onChange: (startDate: string, endDate: string) => void
  isError?: boolean
  isRequired?: boolean
  startDate?: string
  endDate?: string
  showTime?: boolean
  maxDate?: boolean
  allowClear?: boolean
  disabled?: [boolean, boolean]
  maxMonth?: number
}

const RangeDateItem = (props: Props) => {
  const {
    onChange,
    placeHolder = ['วันเริ่มต้น', 'วันสิ้นสุด'],
    startDate = '',
    endDate = '',
    showTime = false,
    maxDate = false,
    allowClear = true,
    disabled = [false, false],
    maxMonth = 3,
  } = props

  const [currentDate, setCurrentDate] = useState('')

  useEffect(() => {
    setCurrentDate(startDate)
  }, [startDate])

  const handleChangeDate = (event: any) => {
    onChange((event && event[0]) || '', (event && event[1]) || '')
  }

  const onCalendarChange = (event: any) => {
    setCurrentDate((event && event[0]) || '')
  }

  const getDisabledDate = (d: any) => {
    return !d || d.isSameOrAfter(moment(currentDate).add(maxMonth, 'M'))
  }

  return (
    <StyledRangePicker>
      <RangePicker
        placeholder={placeHolder}
        onChange={handleChangeDate}
        value={[toMoment(startDate), toMoment(endDate)]}
        showTime={showTime}
        locale={locale}
        disabledDate={(d: any) => (maxDate ? getDisabledDate(d) : null)}
        onCalendarChange={onCalendarChange}
        allowClear={allowClear}
        disabled={disabled}
      />
    </StyledRangePicker>
  )
}

const StyledRangePicker = styled.div`
  .ant-picker-range {
    width: 100%;
    height: 45px !important;
    border: 1px solid #c5cfd5 !important;
    border-radius: 6px !important;
    padding-top: 5px !important;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  }
  .ant-select-selection-search {
    padding-top: 5px;
  }
`

export default RangeDateItem
