import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import RangeDateItem from './RangeDateItem'

type Props = {
  label?: string
  placeHolder?: [string, string]
  onChange: (startDate: string, endDate: string) => void
  isError?: boolean
  isRequired?: boolean
  startDate?: string
  endDate?: string
  showTime?: boolean
  maxDate?: boolean
  maxMonth?: number
  allowClear?: boolean
  disabled?: [boolean, boolean]
}

const AppRangeDateItem = (props: Props) => {
  const {
    label = '',
    isError = false,
    onChange,
    placeHolder,
    isRequired = false,
    startDate,
    endDate,
    showTime = false,
    maxDate = false,
    maxMonth = 3,
    allowClear,
    disabled = [false, false],
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${label}`}>
      <LabelItem label={isRequired ? `${label}*` : label} /> <br />
      <RangeDateItem
        placeHolder={placeHolder}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        showTime={showTime}
        maxDate={maxDate}
        maxMonth={maxMonth}
        allowClear={allowClear}
        disabled={disabled}
      />
    </FormItem>
  )
}

export default AppRangeDateItem
