import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Col, Row, Space, Tabs } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { selectCountCustomer, selectList, selectListEvent } from 'Store/customer/selector'
import { formatDateTime, formatTaskype, getCustomerTaskStatus, isFilter } from 'helpers/format'
import { StatusTag } from 'Components/utilities'
import { TableListPage } from 'Components/common/Table'
import { ActionButton, PrimaryButton } from 'Components/button'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { Pagination } from 'Components/Pagination'
import { SearchInput } from 'Components/form'
import LinkText from 'Components/text/LinkText'
import { Theme } from 'Components/common'
import { cloneDeep } from 'lodash'
import { EditOutlined, EyeOutlined, FileSearchOutlined, SearchOutlined } from '@ant-design/icons'
import { menuCustomerTaskStatus } from 'helpers/constanst'
import CustomerDetail from './detail'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { countCustomerAPI, getCustomerTaskListAPI } from 'Store/customer/api'
import { CountReq, DataList, ListReq } from 'Store/customer/types'
import theme from 'Components/common/Theme'
const { TabPane } = Tabs

type Props = {} & RouteComponentProps & ReduxProps

const CustomerTask: React.FunctionComponent<Props> = props => {
  const { listEvent, dataList, loginData, countData, userProfile } = props
  const dispatch = useDispatch()
  const [resetFilter, setResetFilter] = useState(false)
  const [selected, setSelected] = useState({} as DataList)
  const [drawer, setDrawer] = useState(false)
  const [activeStatus, setActiveStatus] = useState('' as string)

  const initFilter = {
    start_date: '',
    end_date: '',
    merchant_id: 0,
    keyword: '',
    status: '',
  }
  const [filter, setFilter] = useState(cloneDeep(initFilter))
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })
  const meta = dataList.meta
  const datas = dataList.data

  const onGetDataList = (): void => {
    const req: ListReq = {
      page: pagination.Page,
      per_page: pagination.RowPerPage,
      status: activeStatus,
      keyword: filter.keyword,
    }
    dispatch(getCustomerTaskListAPI(req, loginData?.access_token || ''))
  }

  const onCountCustomer = (): void => {
    const req: CountReq = {}
    dispatch(countCustomerAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (loginData?.access_token) {
      onGetDataList()
      onCountCustomer()
    }
  }, [pagination])

  useEffect(() => {
    if (resetFilter) {
      onGetDataList()
      setResetFilter(false)
    }
  }, [resetFilter])

  const onClickSearch = () => {
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  useEffect(() => {
    if (pagination.Page === 1) return
    pagination.Page = 1
    pagination.RowPerPage = 10
    setPagination({ ...pagination })
  }, [])

  const onFilter = (key: string, val: string) => {
    setFilter({
      ...filter,
      [key]: val,
    })
  }

  const onOpenDrawer = () => {
    setDrawer(true)
  }

  const onCloseDrawer = () => {
    setDrawer(false)
  }

  const onClickEdit = (item: DataList) => {
    setSelected(item)
    onOpenDrawer()
  }

  const onSuccess = () => {
    // onCloseDrawer()
    onGetDataList()
    onCountCustomer()
  }

  const onResetFilter = () => {
    setFilter(cloneDeep({ ...initFilter }))
    setResetFilter(true)
  }

  const renderStatus = (value: any) => {
    const result = getCustomerTaskStatus(value)
    return <StatusTag color={result.color} text={result.text} />
  }

  const onChangeTab = (key: string) => {
    setActiveStatus(key)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  const renderActionButton = (text: string, record: DataList) => {
    const isOwnTask = record?.user_id === userProfile?.id
    return (
      <Row justify="center" gutter={[8, 8]}>
        <Col>
          {isOwnTask ? (
            <ActionButton
              text=""
              icon={<EditOutlined />}
              label="แก้ไขข้อมูล"
              action="default"
              onClick={() => onClickEdit(record)}
              size="middle"
            />
          ) : (
            <ActionButton
              text=""
              icon={<FileSearchOutlined />}
              label="ดูข้อมูล"
              action="view"
              onClick={() => onClickEdit(record)}
              size="middle"
            />
          )}
        </Col>
      </Row>
    )
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
      render: (value: any, record: any, index: number) => {
        return index + 1 + (pagination.Page - 1) * pagination.RowPerPage
      },
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'first_name',
      key: 'first_name',
      //render: renderAgentName,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '120px',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'วันที่สร้างข้อมูล',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (val: string) => formatDateTime(val),
    },
    {
      title: 'ประเภท',
      dataIndex: 'task_type',
      key: 'task_type',
      render: (val: string) => formatTaskype(val),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: renderStatus,
    },
    {
      title: '',
      dataIndex: 'submit',
      key: 'submit',
      width: '80px',
      render: renderActionButton,
    },
  ]

  const DetailDrawer = (
    <CustomerDetail
      id={selected?.id || 0}
      visible={drawer}
      onCloseDrawer={onCloseDrawer}
      onSuccess={onSuccess}
    />
  )

  const FilterContent = (
    <FilterWrapper>
      <Row justify="start" align="bottom" gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <SearchInput
            name="keyword"
            placeholder="ค้นหาข้อมูลลูกค้า"
            value={filter.keyword}
            onChange={onFilter}
          />
        </Col>
        <Col>
          <Space size="middle" align="center">
            <PrimaryButton text="ค้นหา" btnType="Cancel" onClick={onClickSearch} key="search" />
            {isFilter(filter) && (
              <LinkText color={Theme.colors.primary} onClick={onResetFilter}>
                ล้างการค้นหา
              </LinkText>
            )}
          </Space>
        </Col>
      </Row>
    </FilterWrapper>
  )

  const PaginationContent = (
    <Pagination
      currentPage={pagination.Page}
      totalRows={meta?.totalCount || 0}
      perPage={pagination.RowPerPage}
      onClickPage={(page: number) => {
        pagination.Page = page
        setPagination({ ...pagination })
      }}
      onChangeNumber={(number: number) => {
        pagination.RowPerPage = number
        setPagination({ ...pagination })
      }}
    />
  )

  const renderTabs = () => {
    return (
      <StickyHeader>
        <Tabs onChange={onChangeTab} activeKey={activeStatus}>
          {menuCustomerTaskStatus.map((item: any) => {
            const key = item.value === '' ? 'all' : item.value
            const count = (countData && countData[key]) || 0
            return <TabPane tab={`${item.name}(${count})`} key={item.value}></TabPane>
          })}
        </Tabs>
      </StickyHeader>
    )
  }

  return (
    <>
      <HomePageLayout selected="customer-task" title="ข้อมูลลูกค้า" label="">
        {renderTabs()}
        {FilterContent}
        <TableListPage
          columns={columns}
          datas={datas}
          isLoading={listEvent.IsLoading}
          paginationContent={null}
        />
        {PaginationContent}
        {/* {LoadingModal} */}
        {DetailDrawer}
      </HomePageLayout>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectList(state),
    listEvent: selectListEvent(state),
    loginData: selectLoginData(state),
    countData: selectCountCustomer(state),
    userProfile: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTask)

const FilterWrapper = styled.div`
  padding: 10px 0 5px 0;
`

const StickyHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -25px;
  z-index: 1;
  background-color: ${theme.colors.whitebg};
`
