import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
  AppstoreOutlined,
  UserOutlined,
  MessageOutlined,
  TeamOutlined,
  SettingOutlined,
  FileTextOutlined,
  QrcodeOutlined,
  UsergroupAddOutlined,
  FileDoneOutlined,
  DashboardOutlined,
  AlertOutlined,
  BankOutlined,
} from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { connect } from 'react-redux'
import { MyTheme } from 'Components/common/Theme'
import RootState from 'typings/RootState'
import { selectUserProfile } from 'Store/user/selector'
import LogoImage from 'Components/logo/LogoImage'
import LogoAgent from 'Components/logo/LogoAgent'
import { AgentType, UserAgentType, UserType } from 'helpers/constanst'
import { isMobile, isIPad13 } from 'react-device-detect'

const { Sider } = Layout
const { SubMenu } = Menu

type AppProps = {
  selected: string
}

type Props = AppProps & ReduxProps & DispatchProps

const SideMenu: React.FunctionComponent<Props> = props => {
  const { selected = '', userProfile } = props
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const breakpoint = useBreakpoint()
  const xs = breakpoint.xs || isMobile || isIPad13
  const userType = userProfile?.user_type || ''
  const level = userProfile?.level
  const salePermission = userProfile?.profile?.partner_type === UserAgentType.CHATSALE
  const hideChatMenu = userProfile?.profile?.partner_type === UserAgentType.NOCHAT
  const branchPermission = userProfile?.is_branch_email || false
  const showAMPDashboard = !!userProfile?.dashboard_url?.report_id

  const agentMenu = [
    {
      name: 'แดชบอร์ด',
      to: '/dashboard',
      icon: <DashboardOutlined />,
      subMenu: [],
      key: 'dashboard',
    },
    showAMPDashboard && {
      name: 'AMP Dashboard',
      to: '/amp-dashboard',
      icon: <DashboardOutlined />,
      key: 'amp-dashboard',
    },
    // {
    //   name: 'ค้นหาราคาประเมินรถ',
    //   to: '/explor',
    //   icon: <CarOutlined />,
    //   key: 'explor',
    // },
    {
      name: 'บัญชีของฉัน',
      to: '/profile',
      icon: <UserOutlined />,
      key: 'profile',
    },
    !hideChatMenu && {
      name: 'แชท',
      to: '/live-chat',
      icon: <MessageOutlined />,
      key: 'live-chat',
    },
    {
      name: 'ข้อมูลลูกค้า',
      to: '/customer-task',
      icon: <TeamOutlined />,
      key: 'customer-task',
    },
    salePermission && {
      name: 'เคสที่ส่งไปยังสาขา',
      to: '/sale-task',
      icon: <FileDoneOutlined />,
      key: 'sale-task',
    },
    level != 4 && {
      name: 'จัดการตัวแทน',
      to: '/manage-agent',
      icon: <UsergroupAddOutlined />,
      key: 'manage-agent',
    },
    level != 4 && {
      name: 'Stand by report',
      to: '/report-standby',
      icon: <AlertOutlined />,
      key: 'report-standby',
    },
    {
      name: 'รายงานการสนทนา',
      to: '/report-chat',
      icon: <FileTextOutlined />,
      key: 'report-chat',
    },
    {
      name: 'ตั้งค่าข้อความ',
      to: '/setting-message',
      icon: <SettingOutlined />,
      key: 'setting-message',
    },
    branchPermission && {
      name: 'ข้อมูลลูกค้าที่นำส่งสาขา',
      to: '/branch-task',
      icon: <BankOutlined />,
      key: 'branch-task',
    },
  ] as MenuInterface[]

  const adminMenu = [
    // {
    //   name: 'แดชบอร์ด',
    //   to: '/dashboard',
    //   icon: <AppstoreOutlined />,
    //   subMenu: [],
    //   key: 'dashboard',
    // },
    {
      name: 'ข้อมูล Agent',
      to: '/agents',
      icon: <TeamOutlined />,
      key: 'agents',
    },
    {
      name: 'Partner QR Code',
      to: '/partner-qrcode',
      icon: <QrcodeOutlined />,
      key: 'partner-qrcode',
    },
    {
      name: 'ข้อมูลตัวแทน',
      to: '/agent-qrcode',
      icon: <UsergroupAddOutlined />,
      key: 'agent-qrcode',
    },
  ] as MenuInterface[]

  const saleMenu = [
    // {
    //   name: 'แดชบอร์ด',
    //   to: '/dashboard',
    //   icon: <AppstoreOutlined />,
    //   subMenu: [],
    //   key: 'dashboard',
    // },
    {
      name: 'ข้อมูลลูกค้า',
      to: '/customer-task',
      icon: <TeamOutlined />,
      key: 'customer-task',
    },
  ] as MenuInterface[]

  const adminQrMenu = [
    {
      name: 'สร้าง Partner QR Code',
      to: '/partner-qrcode',
      icon: <QrcodeOutlined />,
      key: 'partner-qrcode',
    },
  ] as MenuInterface[]

  const partnerQrMenu = [
    {
      name: 'แดชบอร์ด',
      to: '/dashboard',
      icon: <DashboardOutlined />,
      subMenu: [],
      key: 'dashboard',
    },
    {
      name: 'Profile ของฉัน',
      to: '/profile',
      icon: <QrcodeOutlined />,
      key: 'profile',
    },
  ] as MenuInterface[]

  const defaultMenu = [] as MenuInterface[]

  useEffect(() => {
    if (xs) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }, [xs])

  const onCollapse = (collapsed: boolean): void => {
    setCollapsed(collapsed)
  }

  let configMenu = [] as MenuInterface[]
  switch (userType) {
    case UserType.ADMIN:
      configMenu = adminMenu
      break
    case UserType.AGENT:
      configMenu = agentMenu
      break
    case UserType.SALE:
      configMenu = saleMenu
      break
    case UserType.ADMINQR:
      configMenu = adminQrMenu
      break
    case UserType.QRPARTNER:
      configMenu = partnerQrMenu
      break
    default:
      configMenu = defaultMenu
      break
  }

  const slideMenu = (menu: MenuInterface[]) => {
    return (
      <Menu mode="inline" theme="light" style={{ height: '100%', borderRight: 0 }} selectedKeys={[selected]}>
        <LogoSection>
          <LinkLogo to="/">
            {userProfile?.user_type === UserType.AGENT && userProfile?.agent_image ? (
              <LogoAgent agentImage={userProfile?.agent_image} width={collapsed ? 40 : 80} />
            ) : (
              <LogoImage primaryColor={false} shortLogo={collapsed} width={collapsed ? 60 : 150} />
            )}
          </LinkLogo>
        </LogoSection>
        {menu.map((menu: MenuInterface) => {
          if (!menu) return
          const hasSubmenu = menu.subMenu && menu.subMenu.length > 0
          if (hasSubmenu) {
            return (
              <SubMenu key={menu.key} icon={menu.icon} title={menu.name}>
                {menu.subMenu.map((subMenu: SubMenuInterface) => {
                  return (
                    <Menu.Item key={subMenu.key}>
                      <Link to={subMenu.to}>{subMenu.name}</Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          } else {
            if (menu.key === 'setting-message' && level != 0) return
            return (
              <Menu.Item key={menu.key} icon={menu.icon}>
                <Link to={menu.to}>{menu.name}</Link>
              </Menu.Item>
            )
          }
        })}
      </Menu>
    )
  }

  return (
    <SlideArea
      width={200}
      className="site-layout-background"
      //collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth={60}
    >
      {slideMenu(configMenu)}
    </SlideArea>
  )
}

const SlideArea = styled(Sider)`
  z-index: 10;
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};

  .ant-menu {
    background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.whiteInactive};
  }

  .ant-menu-item a,
  .ant-menu-item a:hover,
  .ant-menu-item:hover,
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:active > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.whiteInactive};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    span,
    a {
      color: #fff;
    }
  }

  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary2};
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    background: none;
  }
`

const LogoSection = styled.div`
  padding: 40px 0 20px 0;
  text-align: center;
`

const LinkLogo = styled(Link)``

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
