import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { Col, Row } from 'antd'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import styled from 'styled-components'
import { InfoItem } from 'Components/text'
import AgentQrCode from 'Components/utilities/AgentQrCode'
import { selectLoginData } from 'Store/user/selector'
import { clearEventAction } from 'Store/agent/actions'
import { EventModal, LoadingView } from 'Components/modal'
import { selectActionEvent } from 'Store/agent/selector'
import { getPartnerCodesAPI, getPartnerProfileAPI } from 'Store/partner-qr/api'
import { ProfileReq } from 'Store/partner-qr/types'
import { selectPartnerCode, selectPartnerProfile } from 'Store/partner-qr/selector'
import { selectDetailEvent } from 'Store/customer/selector'
import { getBankName } from 'helpers/format'
// import { LoadingView } from 'Components/modal'

type Props = {} & RouteComponentProps & ReduxProps

const QrPartnerProfile: React.FunctionComponent<Props> = props => {
  const { loginData, actionEvent, detail, detailEvent, partnerCodes } = props
  const dispatch = useDispatch()

  const onGetProfileInfo = (): void => {
    const req: ProfileReq = {}
    dispatch(getPartnerProfileAPI(req, loginData?.access_token || ''))
  }

  const getMasterData = (): void => {
    dispatch(getPartnerCodesAPI({}, loginData?.access_token || ''))
  }

  useEffect(() => {
    // setLogo('')
    onGetProfileInfo()
    getMasterData()
  }, [])

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    onGetProfileInfo()
  }

  const getName = (key: string, val: any) => {
    const result =
      partnerCodes &&
      partnerCodes[key] &&
      partnerCodes[key].find((item: { [key: string]: any }) => {
        return Object.keys(item) == val
      })
    return result ? `${Object.keys(result)} - ${Object.values(result)}` : ''
  }

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ParentInfo = (
    <BoxWrapper title="โครงสร้างสายงาน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('หัวหน้างาน', detail?.parent_agent_code), null)}
        {renderInfoRow(
          renderInfo('Channel Code', getName('channel_code', detail?.channel_code || '')),
          renderInfo('Industry Code', getName('industry_code', detail?.industry_code)),
        )}
        {renderInfoRow(renderInfo('Level1-Company Code', `${detail?.company_code || ''}`), null)}
      </Row>
    </BoxWrapper>
  )

  const RegisterInfo = (
    <BoxWrapper title="ข้อมูลผู้ใช้งาน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('Partner QR Name', detail?.qr_name), renderInfo('อีเมล', detail?.email))}
        {renderInfoRow(renderInfo('ชื่อ', detail?.first_name), renderInfo('นามสกุล', detail?.last_name))}
        {renderInfoRow(
          renderInfo('ชื่อนิติบุคคล', detail?.corporate_name),
          renderInfo('เลขทะเบียนนิติบุคคล', detail?.corporate_no),
        )}
        {renderInfoRow(renderInfo('เบอร์โทรศัพท์', detail?.mobile), renderInfo('ที่อยู่', detail?.address))}
      </Row>
    </BoxWrapper>
  )

  const FinanceInfo = (
    <BoxWrapper title="ข้อมูลด้านการเงิน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ธนาคาร', getBankName(detail?.bank_name)),
          renderInfo('สาขา', detail?.bank_branch),
        )}
        {renderInfoRow(
          renderInfo('ชื่อบัญชี', detail?.bank_account_name),
          renderInfo('เลขที่บัญชี', detail?.bank_account_no),
        )}
        {renderInfoRow(renderInfo('เรทคอมมิชชั่น', `${detail?.commission || ''} %`), null)}
      </Row>
    </BoxWrapper>
  )

  return (
    <HomePageLayout selected="profile" title="บัญชีของฉัน" label={''}>
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal
        key="profile"
        event={[actionEvent]}
        enable={true}
        successCalback={onSuccess}
        errorCalback={clearEvent}
      />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          <BoxWrapper title={`Agent ID : ${detail?.agent_code || ''}`}>
            <Center>
              <AgentQrCode
                title={detail?.user?.uuid || ''}
                uuid={detail?.user?.uuid || ''}
                agentType={'agen-qr'}
                width={200}
                domain={detail?.user?.domain || ''}
                userGroup={detail?.user?.user_group || ''}
                agentCode={detail?.agent_code || ''}
              />
            </Center>
          </BoxWrapper>
        </Col>
        <Col md={24} xs={24}>
          {ParentInfo}
        </Col>
        <Col md={24} xs={24}>
          {RegisterInfo}
        </Col>
        <Col md={24} xs={24}>
          {FinanceInfo}
        </Col>
      </Row>
    </HomePageLayout>
  )
}

const Center = styled.div`
  width: max-content;
  margin: 0 auto;
`

const mapStateToProps = (state: RootState) => {
  return {
    actionEvent: selectActionEvent(state),
    loginData: selectLoginData(state),
    detail: selectPartnerProfile(state),
    detailEvent: selectDetailEvent(state),
    partnerCodes: selectPartnerCode(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(QrPartnerProfile)
