import React, { useEffect, useState } from 'react'
import 'assets/styles/App.css'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import {
  Login,
  PageNotFound,
  Dashboard,
  NoPermission,
  Chat,
  AgentProfile,
  ChatConsumer,
  AgentRegister,
  Agents,
  SetupPassword,
  ResetPassword,
  CustomerTask,
  SettingMessage,
  Report,
  ChaiyoCustomerTask,
  LineLogin,
  SaleChaiyoCustomerTask,
  CreateQrcodePartner,
  ApprovePartnerQrcode,
  PartnerQrcodeProfile,
  PartnerQrcodeDashboard,
  ManageAgent,
  ApproveAgentQrcode,
  AgentSaleTask,
  LineLoginChaiyo,
  ReportStandBy,
  ChaiyoBranchTask,
  DashboardIframe,
} from 'Pages'
import { getLoginData, getSelfUser, getUserProfile, saveSelfUser } from 'helpers/account'
import { updateLoginDataAction, updateUserProfileAction } from 'Store/user/actions'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { appConfig } from 'config'
import './assets/scss/themes.scss'
import { io } from 'socket.io-client'
import {
  activeUserAction,
  agentHistoryChatAction,
  appendFriendAction,
  disconnectedFriendAction,
  friendListAction,
  onBookedFriend,
  onTypedAction,
  onTypingAction,
  onUpdateAgentQrAction,
  recieveChatFromAction,
  selfChatFromAction,
} from 'Store/chat/actions'
import { ReciveMessageSocket, SelfeMessageSocket, User, UserSocket } from 'Store/chat/types'
import { selectChatActiveUser, selectChatUsers, selectUserChange } from 'Store/chat/selector'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { AgentType, UserAgentType, UserType } from 'helpers/constanst'
import { findUserById } from 'Store/chat/function'
import ReactGA from 'react-ga'
import { selectLastReply } from 'Store/chaiyo-customer/selector'
import { consoleLog } from 'helpers/utils'

type AppProps = {}
type Props = ReduxProps & DispatchProps & AppProps

const App: React.FunctionComponent<Props> = props => {
  const { location, active_user, users, userProfile, loginData, lastReply, hasChange } = props
  let socket = null as any
  const serverURL = appConfig.socketEndpoint
  socket = io(serverURL, {
    autoConnect: false,
    withCredentials: true,
    transports: ['websocket', 'polling'],
  }) as any
  const pathName = window.location.pathname
  const selfUser = getSelfUser()
  const [emitSocket, setEmitSocket] = useState(null as any)
  const isLogin = userProfile && loginData && loginData?.access_token ? true : false
  const dispatch = useDispatch()
  const userModule = userProfile?.user_type || 'admin'
  const hostname = window.location.hostname
  const isPublicHost =
    hostname === appConfig.chatHostname ||
    hostname === appConfig.registerHostname ||
    hostname === appConfig.chaiyoHostname
  const isChaiyo = userProfile?.user_group === 'chaiyo'
  const salePermission = userProfile?.profile?.partner_type === UserAgentType.CHATSALE
  const branchPermission = userProfile?.is_branch_email || false
  const hideChatMenu = userProfile?.profile?.partner_type === UserAgentType.NOCHAT
  const level = userProfile?.level
  const showManageAgent = level != 4

  useEffect(() => {
    if (hostname === appConfig.chatHostname) {
      ReactGA.initialize(appConfig.gaTrackingIdOPD)
    }

    if (hostname === appConfig.chaiyoHostname) {
      ReactGA.initialize(appConfig.gaTrackingIdChaiyo)
    }
  }, [])

  useEffect(() => {
    // get first time when user refresh app
    const storageLoginData = getLoginData()
    const storageUserProfile = getUserProfile()
    dispatch(updateLoginDataAction({ data: storageLoginData }))
    dispatch(updateUserProfileAction({ data: storageUserProfile }))
  }, [])

  useEffect(() => {
    if (userModule === UserType.AGENT && !isPublicHost && !hideChatMenu) {
      const chatType = userProfile?.parent?.agent_type === AgentType.COPORATE ? 'broadcast' : 'broadcast'
      const agentInfo = { userType: 'agent', chatType: chatType, token: loginData?.access_token || '' }
      connectUser(agentInfo)
    }
    return () => socket.close()
  }, [userProfile])

  useEffect(() => {
    if (socket) {
      setEmitSocket(socket)
    }
  }, [userProfile])

  useEffect(() => {
    consoleLog('useEffect emitSocket', emitSocket)
    if (emitSocket && lastReply) {
      if (lastReply?.success && lastReply?.taskID) {
        sendTobranch(lastReply?.taskID)
      }
    }
  }, [lastReply?.success, lastReply?.taskID])

  const connectUser = async (user: any) => {
    try {
      socket.auth = user
      await socket.connect()
      consoleLog('agent connect socket', socket)
    } catch (error) {
      consoleLog('error ', error)
    }
  }

  socket.on('self profile', (user: UserSocket) => {
    saveSelfUser(user)
  })

  socket.on('friend lists', (users: UserSocket[]) => {
    consoleLog('friend lists', users)
    dispatch(
      friendListAction({ users: users, uuid: userProfile?.uuid || '', myAssignID: selfUser?.assignID || '' }),
    )
  })

  socket.on('user connected', (user: UserSocket) => {
    consoleLog('user connected', user)
    dispatch(appendFriendAction({ user: user }))
  })

  socket.on('user disconnected', (user: UserSocket) => {
    dispatch(disconnectedFriendAction({ user: user }))
  })

  socket.on('private message', (message: ReciveMessageSocket) => {
    dispatch(recieveChatFromAction({ recieveMessage: message, uuid: userProfile?.uuid || '' }))
  })

  socket.on('self message', (message: SelfeMessageSocket) => {
    dispatch(selfChatFromAction({ recieveMessage: message }))
  })

  socket.on('connect_error', (err: any) => {
    if (err?.data) {
      const { err_code, err_text } = err.data
      consoleLog(`connect_error : ${err.message}, ${err_code}, ${err_text}`)
      if (err_code == 'NOT_RECONNECT') {
        socket.disconnect()
        alert(`connect_error : ${err.message}, ${err_code}, ${err_text}`)
      }
    } else {
      consoleLog(`connect_error : ${err.message}`)
    }
  })

  socket.on('history message', (history: ReciveMessageSocket[]) => {
    consoleLog('history message', history)
    dispatch(agentHistoryChatAction({ messages: history }))
  })

  socket.on('agent_info', (user: UserSocket) => {
    consoleLog('agent_info', user)
    dispatch(onUpdateAgentQrAction({ user: user }))
  })

  socket.on('typing', (fromUser: User) => {
    dispatch(onTypingAction({ user: fromUser }))
  })

  socket.on('typed', (fromUser: User) => {
    dispatch(onTypedAction({ user: fromUser }))
  })

  socket.on('book friend', (user: UserSocket) => {
    consoleLog('book friend', user)
    dispatch(onBookedFriend({ user: user, uuid: userProfile?.uuid || '' }))
  })

  const addMessage = (message: any, type: string = 'text') => {
    const activeUser = findUserById(users, active_user)
    const toUser = activeUser?.userSocket
    if (!toUser) return
    const content = {
      cBody: message,
      cType: type,
    }
    emitSocket.emit('private message', { toUser, content })
    onTyping(false)
  }

  const onSelectCustomerChat = (active_user: number) => {
    const activeUser = findUserById(users, active_user)
    const currentMessage = activeUser?.messages
    if ((!currentMessage || !activeUser?.hasHistory) && userModule === UserType.AGENT) {
      consoleLog('selected consumer', activeUser?.userSocket)
      consoleLog('emitSocket', emitSocket)
      emitSocket && emitSocket.emit('selected consumer', activeUser?.userSocket)
    }
  }

  const onTyping = (typing: boolean) => {
    const activeUser = findUserById(users, active_user)
    const user = activeUser?.userSocket
    if (typing) {
      emitSocket && emitSocket.emit('typing', { fromUser: selfUser, toUser: user })
    } else {
      emitSocket && emitSocket.emit('typed', { fromUser: selfUser, toUser: user })
    }
  }

  const onBookFriend = (action: 'book' | 'return' | 'end') => {
    const activeUser = findUserById(users, active_user)
    const user = activeUser?.userSocket
    emitSocket && emitSocket.emit('book friend', { agent: selfUser, consumer: user, action: action })
    if (action === 'return' || action === 'end') {
      dispatch(activeUserAction({ userId: 0 }))
    }
  }

  const sendTobranch = (taskID: string) => {
    consoleLog('-----sendTobranch------')
    consoleLog('emitSocket', emitSocket, { taskID: taskID })
    emitSocket.emit('to branch', { taskID: taskID })
  }

  useEffect(() => {
    consoleLog('click user')
    if (active_user && users && users.length > 0) {
      onSelectCustomerChat(active_user)
    }
  }, [active_user, hasChange])

  if (hostname === appConfig.chatHostname) {
    return (
      <Switch>
        <Route path="/:uuid/:userId" render={props => <ChatConsumer {...props} />} />
        <Route exact component={LineLogin} />
        {/* <Route render={props => <ChatConsumer {...props} />} /> */}
      </Switch>
    )
  }

  if (hostname === appConfig.chaiyoHostname) {
    return (
      <Switch>
        {/* <Route render={props => <ChatConsumer {...props} />} /> */}
        <Route path="/:uuid/:userId" render={props => <ChatConsumer {...props} />} />
        <Route exact component={LineLoginChaiyo} />
      </Switch>
    )
  }

  if (hostname === appConfig.registerHostname) {
    return (
      <Switch>
        <Route component={AgentRegister} />
      </Switch>
    )
  }

  if (!isLogin) {
    return (
      <Switch>
        {/* <Route exact path="/chat/:uuid/:type" component={ChatConsumer} /> */}
        <Route exact path="/agent/register" component={AgentRegister} />
        <Route exact path="/setup-password/:token" component={SetupPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route component={Login} />
      </Switch>
    )
  } else {
    if (pathName === '/login') {
      return <Redirect to={{ pathname: '/', state: { from: location, can: false } }} />
    }
  }

  const adminRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={Agents} />
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        <Route exact path="/agents" component={Agents} />
        <Route exact path="/partner-qrcode" component={ApprovePartnerQrcode} />
        <Route exact path="/agent-qrcode" component={ApproveAgentQrcode} />
        <Route component={PageNotFound} />
      </Switch>
    )
  }

  const agentRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/amp-dashboard" component={DashboardIframe} />
        <Route
          exact
          path="/live-chat"
          render={props => (
            <Chat {...props} addMessage={addMessage} onTyping={onTyping} onBookFriend={onBookFriend} />
          )}
        />
        <Route exact path="/profile" component={AgentProfile} />
        {isChaiyo ? (
          <Route exact path="/customer-task" component={ChaiyoCustomerTask} />
        ) : (
          <Route exact path="/customer-task" component={CustomerTask} />
        )}
        <Route exact path="/setting-message" component={SettingMessage} />
        <Route exact path="/report-chat" component={Report} />
        {salePermission && <Route exact path="/sale-task" component={AgentSaleTask} />}
        {branchPermission && <Route exact path="/branch-task" component={ChaiyoBranchTask} />}
        {showManageAgent && <Route exact path="/manage-agent" component={ManageAgent} />}
        <Route exact path="/report-standby" component={ReportStandBy} />
        <Route component={PageNotFound} />
      </Switch>
    )
  }

  const saleRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={SaleChaiyoCustomerTask} />
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        <Route exact path="/customer-task" component={SaleChaiyoCustomerTask} />
        <Route component={PageNotFound} />
      </Switch>
    )
  }

  const adminQrRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={CreateQrcodePartner} />
        <Route exact path="/partner-qrcode" component={CreateQrcodePartner} />
        <Route component={PageNotFound} />
      </Switch>
    )
  }

  const partnerQrRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={PartnerQrcodeDashboard} />
        <Route exact path="/dashboard" component={PartnerQrcodeDashboard} />
        <Route exact path="/profile" component={PartnerQrcodeProfile} />
        <Route component={PageNotFound} />
      </Switch>
    )
  }

  const defaultRoutes = () => {
    return (
      <Switch>
        <Route component={NoPermission} />
      </Switch>
    )
  }

  switch (userModule) {
    case UserType.ADMIN:
      return adminRoutes()
    case UserType.AGENT:
      return agentRoutes()
    case UserType.SALE:
      return saleRoutes()
    case UserType.ADMINQR:
      return adminQrRoutes()
    case UserType.QRPARTNER:
      return partnerQrRoutes()
    default:
      return defaultRoutes()
  }
}

const mapStateToProps = (state: RootState): any => {
  return {
    active_user: selectChatActiveUser(state),
    users: selectChatUsers(state),
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
    lastReply: selectLastReply(state),
    hasChange: selectUserChange(state),
  }
}

const mapDispatchToProps = {}
type DispatchProps = typeof mapDispatchToProps

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(withRouter(App))
