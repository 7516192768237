import { User } from 'types'
import RootState from 'typings/RootState'

export const selectChatUsers = (state: RootState): User[] => state.chatReducer.users

export const selectChatContact = (state: RootState): any => state.chatReducer.contacts

export const selectChatActiveUser = (state: RootState): any => state.chatReducer.active_user

export const selectGroup = (state: RootState): any => state.chatReducer.groups

export const selectUserSidebar = (state: RootState): boolean => state.chatReducer.userSidebar

export const selectUserChange = (state: RootState): boolean => state.chatReducer.change
