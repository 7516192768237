import withAPIAction from 'helpers/withAPIAction'
import { ListReq, CountReq } from './types'
import { getListAction, countAction } from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getStandbyReportListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getStandbyReportList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const countStandbyReportAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.countStandbyReport}?${getRequestString(data)}`,
    data,
    token,
  })(countAction)
}
