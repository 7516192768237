import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Badge, Button, Card, Col, message, Row, Space } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { ListReq, LogProfileData, CountReq } from 'Store/report-standby/types'
import { selectCount, selectList, selectListEvent } from 'Store/report-standby/selector'
import {
  currentDate,
  currentDateTimeTh,
  formatDateTime,
  formatDateWithoutTime,
  getLoginStatus,
  getRequestString,
  isFilter,
  numberWithComma,
  toDateQuery,
} from 'helpers/format'
import { TableListPage } from 'Components/common/Table'
import { PrimaryButton } from 'Components/button'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { Pagination } from 'Components/Pagination'
import { SearchInput, SelectItem } from 'Components/form'
import LinkText from 'Components/text/LinkText'
import { Theme } from 'Components/common'
import { cloneDeep } from 'lodash'
import { menuLoginStatus } from 'helpers/constanst'
import { selectLoginData } from 'Store/user/selector'
import { getStandbyReportListAPI, countStandbyReportAPI } from 'Store/report-standby/api'
import { ConfirmExport } from 'Components/modal'
import { appConfig } from 'config'
import Axios from 'axios'
import downloadCSVFile from 'helpers/export'
import { APIPath } from 'services/APIPath'
import { RedoOutlined } from '@ant-design/icons'
import { MyTheme } from 'Components/common/Theme'

type Props = {} & RouteComponentProps & ReduxProps

const ManageAgent: React.FunctionComponent<Props> = props => {
  const { listEvent, dataList, loginData, countData } = props
  const dispatch = useDispatch()
  const [resetFilter, setResetFilter] = useState(false)
  const [exportDailog, setExportDailog] = useState(false)
  const [lastUpdate, setLastUpdate] = useState('')
  const [exporting, setExporting] = useState(false)

  const initFilter = {
    start_date: currentDate(),
    end_date: currentDate(),
    merchant_id: 0,
    keyword: '',
    status: '',
    partner_status: '',
  }
  const [filter, setFilter] = useState(cloneDeep(initFilter))
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })
  const meta = dataList.meta
  const datas = dataList.data

  const onGetDataList = (): void => {
    setLastUpdate(currentDateTimeTh())
    const req: ListReq = {
      page: pagination.Page,
      per_page: pagination.RowPerPage,
      status: filter.status,
      keyword: filter.keyword,
      start_date: filter.start_date ? toDateQuery(filter.start_date) : '',
      end_date: filter.end_date ? toDateQuery(filter.end_date) : '',
    }
    dispatch(getStandbyReportListAPI(req, loginData?.access_token || ''))
  }

  const onCountData = () => {
    const req: CountReq = {
      page: pagination.Page,
      per_page: pagination.RowPerPage,
      status: filter.status,
      keyword: filter.keyword,
      start_date: filter.start_date ? toDateQuery(filter.start_date) : '',
      end_date: filter.end_date ? toDateQuery(filter.end_date) : '',
    }
    dispatch(countStandbyReportAPI(req, loginData?.access_token || ''))
  }

  const onExport = (start_date: string, end_date: string): void => {
    setExporting(true)
    const req: ListReq = {
      start_date: toDateQuery(start_date),
      end_date: toDateQuery(end_date),
      keyword: filter.keyword,
      status: filter.status,
    }

    const endPoint = `${appConfig.apiEndpoint}${APIPath.exportStandbyReportList}?${getRequestString(req)}`
    const header = {
      headers: {
        Authorization: `Bearer ${loginData?.access_token || ''}`,
      },
    }
    Axios.get(endPoint, header)
      .then((res: any) => {
        const reportData = res?.data
        const fileName = `รายงานการ Login ประจำวันที่ ${formatDateWithoutTime(
          start_date,
        )} ถึง ${formatDateWithoutTime(end_date)}.csv`
        downloadCSVFile(reportData, fileName)
        setExporting(false)
      })
      .catch(error => {
        setExporting(false)
        message.error({
          content: error?.response?.data?.error || 'เกิดข้อผิดพลาด ไม่สามารถ Export ข้อมูลได้',
          key: 'export-error',
        })
      })
  }

  useEffect(() => {
    if (loginData?.access_token) {
      onGetDataList()
      onCountData()
    }
  }, [pagination])

  useEffect(() => {
    if (resetFilter) {
      onGetDataList()
      onCountData()
      setResetFilter(false)
    }
  }, [resetFilter])

  const onClickSearch = () => {
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  useEffect(() => {
    if (pagination.Page === 1) return
    pagination.Page = 1
    pagination.RowPerPage = 10
    setPagination({ ...pagination })
  }, [])

  const onFilter = (key: string, val: string) => {
    setFilter({
      ...filter,
      [key]: val,
    })
  }

  const onResetFilter = () => {
    setFilter(cloneDeep({ ...initFilter }))
    setResetFilter(true)
  }

  const renderUserStatus = (value: any) => {
    const result = getLoginStatus(value)
    return <MyBadgeItem key={result.color} color={result.color} text={''} />
  }

  const ConfirmExportModal = (
    <ConfirmExport
      title="ยืนยันดาวน์โหลดรายงานการ Login"
      label="วันที่"
      confirmBtnTxt="ดาวน์โหลด .csv"
      description="หมายเหตุ : สามารถดาวน์โหลดข้อมูลได้สูงสุด 3 เดือน"
      isShow={exportDailog}
      handleOk={onExport}
      handleCancel={() => setExportDailog(false)}
      loading={exporting}
      maxMonth={3}
    />
  )

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
      render: (value: any, record: any, index: number) => {
        return index + 1 + (pagination.Page - 1) * pagination.RowPerPage
      },
    },
    {
      title: 'ชื่อ',
      dataIndex: 'profile',
      key: 'first_name',
      render: (val: LogProfileData) => val?.first_name || '-',
    },
    {
      title: 'นามสกุล',
      dataIndex: 'profile',
      key: 'last_name',
      render: (val: LogProfileData) => val?.last_name || '-',
    },
    {
      title: 'รหัสพนักงาน',
      dataIndex: 'profile',
      key: 'employee_code',
      render: (val: LogProfileData) => val?.employee_code || '-',
    },
    {
      title: 'รหัสตัวแทน',
      dataIndex: 'profile',
      key: 'agent_code',
      render: (val: LogProfileData) => val?.agent_code || '-',
    },
    {
      title: 'วันเวลาที่ Login ล่าสุด',
      dataIndex: 'lasted_login_at',
      key: 'lasted_login_at',
      render: (val: string) => formatDateTime(val),
    },
    {
      title: 'สถานะผู้ใช้งาน',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      align: 'center',
      render: renderUserStatus,
    },
  ]

  const FilterContent = (
    <FilterWrapper>
      <Row justify="space-between" align="bottom" gutter={[16, 16]}>
        <Col md={18} xs={24}>
          <Row align="bottom" gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <SearchInput
                name="keyword"
                placeholder="ค้นหาชื่อ,รหัสตัวแทน"
                value={filter.keyword}
                onChange={onFilter}
              />
            </Col>
            <Col xs={24} md={8}>
              <SelectItem
                name={'status'}
                label={''}
                isRequired={true}
                value={filter.status}
                menu={menuLoginStatus}
                placeHolder={'สถานะผู้ใช้งาน'}
                onChange={(val: any): void => onFilter('status', val)}
              />
            </Col>
            {/* <Col xs={24} md={6}>
          <RangeDateItem
            placeHolder={['วันที่สร้างเริ่มต้น', 'สิ้นสุด']}
            onChange={handleChangeDate}
            startDate={filter.start_date}
            endDate={filter.end_date}
          />
        </Col> */}
            <Col>
              <Space size="middle" align="center">
                <PrimaryButton text="ค้นหา" btnType="Cancel" onClick={onClickSearch} key="search" />
                {isFilter(filter) && (
                  <LinkText color={Theme.colors.primary} onClick={onResetFilter}>
                    ล้างการค้นหา
                  </LinkText>
                )}
              </Space>
            </Col>
          </Row>
        </Col>

        <Col>
          <PrimaryButton
            text="Export รายงานการ Login"
            btnType="Primary"
            onClick={() => setExportDailog(true)}
            key="export"
          />
        </Col>
      </Row>
    </FilterWrapper>
  )

  const PaginationContent = (
    <Pagination
      currentPage={pagination.Page}
      totalRows={meta?.totalCount || 0}
      perPage={pagination.RowPerPage}
      onClickPage={(page: number) => {
        pagination.Page = page
        setPagination({ ...pagination })
      }}
      onChangeNumber={(number: number) => {
        pagination.RowPerPage = number
        setPagination({ ...pagination })
      }}
    />
  )

  const boxItem = (title: string, descriptiones: string, value: any, icon: any, color: string = 'green') => {
    return (
      <MyCard>
        <Space align="start" size="small">
          {icon}
          <Space direction="vertical" size="small">
            <Title>
              {title} &nbsp;<Description>{descriptiones}</Description>
            </Title>
            <Value style={{ color: color }}>{numberWithComma(value)} Account</Value>
          </Space>
        </Space>
      </MyCard>
    )
  }

  return (
    <>
      <HomePageLayout selected="report-standby" title="Stand by report" label="">
        {FilterContent}
        <DescWrapper>
          <Row justify="end" align="middle" gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <BoxTitle>คำอธิบายสถานะผู้ใช้งาน ของตัวแทน</BoxTitle>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    type="dashed"
                    size="middle"
                    shape="round"
                    icon={<RedoOutlined />}
                    onClick={() => {
                      onGetDataList()
                      onCountData()
                    }}
                  >
                    Refresh ข้อมูล
                  </Button>
                  <Small>ข้อมูลเมื่อ {lastUpdate}</Small>
                </Col>
              </Row>
              {/* <Item>
                <div>
                  <MyBadgeItem color="green" />
                  ตัวแทน Login ภายในเวลาปัจจุบันย้อนไป 1 ชั่วโมง = ออนไลน์
                </div>
                <div>
                  <MyBadgeItem color="red" />
                  ตัวแทน Login ค้างไว้ก่อนหน้านั้นมากกว่า 1 ชั่วโมง = ออฟไลน์
                </div>
              </Item> */}
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  {boxItem(
                    'สถานะออนไลน์',
                    '(ตัวแทน Login ภายในเวลาปัจจุบันย้อนไป 1 ชั่วโมง)',
                    countData?.online || 0,
                    <MyBadgeItem color="green" />,
                    'green',
                  )}
                </Col>
                <Col span={12}>
                  {boxItem(
                    'สถานะออฟไลน์',
                    '(ตัวแทน Login ค้างไว้ก่อนหน้านั้นมากกว่า 1 ชั่วโมง)',
                    countData?.offline || 0,
                    <MyBadgeItem color="red" />,
                    'red',
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </DescWrapper>
        <TableListPage
          columns={columns}
          datas={datas}
          isLoading={listEvent.IsLoading}
          paginationContent={null}
          scrollX={800}
        />
        {PaginationContent}
        {ConfirmExportModal}
        {/* {LoadingModal} */}
        {/* {DetailDrawer}
        {FormDrawer} */}
      </HomePageLayout>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectList(state),
    listEvent: selectListEvent(state),
    loginData: selectLoginData(state),
    countData: selectCount(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ManageAgent)

const FilterWrapper = styled.div`
  padding: 10px 0 5px 0;
`

const DescWrapper = styled.div`
  padding: 20px;
  background-color: #ece8f6;
  border-radius: 3px;
  margin-top: 10px;
`

const Item = styled.div`
  padding-left: 20px;
  font-size: 12px;
`

const MyBadgeItem = styled(Badge)`
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
`

const Small = styled.div`
  font-size: 12px;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  text-align: right;
  margin-top: 10px;
`

const BoxTitle = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.bold};
  margin-bottom: 10px;
`

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
`

const Description = styled.span`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
`

const Value = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
`

const MyCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
  }
`
