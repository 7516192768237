import withAPIAction from 'helpers/withAPIAction'
import {
  DetailReq,
  GetCustomerReq,
  ListReq,
  UpdateCustomerReq,
  UpdateCustomerSocketReq,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
} from './types'
import {
  getCustomerInfoAction,
  getDetailAction,
  getListAction,
  updateAction,
  updateCustomerAction,
  updateCustomerSocketAction,
  updateCustomerStatusAction,
  countCustomerTabAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { cloneDeep } from 'lodash'
import { getRequestString } from 'helpers/format'

export const getCustomerByUUIDAPI = (data: GetCustomerReq, token: string) => {
  const endPoint = APIPath.getCustomerByUUID.replace(':uuid', data.uuid.toString())
  return withAPIAction<GetCustomerReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getCustomerInfoAction)
}

export const updateCustomerAPI = (data: UpdateCustomerReq, uuid: string, token: string) => {
  const endPoint = APIPath.updateCustomerInfo.replace(':uuid', uuid)
  // let req = cloneDeep(data)
  // delete req.uuid
  return withAPIAction<UpdateCustomerReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateCustomerAction)
}

export const updateCustomerSocketAPI = (data: UpdateCustomerSocketReq) => {
  return withAPIAction<UpdateCustomerSocketReq>({
    method: 'PUT',
    baseUrl: appConfig.socketEndpoint,
    path: APIPath.updateCustomerSocketInfo,
    data,
  })(updateCustomerSocketAction)
}

export const getCustomerTaskListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.customerTaskList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getCustomertDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.customerTaskByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateCustomerTaskAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateCustomerTask.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateCustomerTaskStatusAPI = (data: UpdateStatusReq, token: string) => {
  const endPoint = APIPath.updateCustomerTaskStatus.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateCustomerStatusAction)
}

export const countCustomerAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.countCustomer,
    data,
    token,
  })(countCustomerTabAction)
}
