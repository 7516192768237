import withAPIAction from 'helpers/withAPIAction'
import { DetailReq, ListReq, UpdateReq, UpdateStatusReq, CountReq, ReasonReq, LogReq } from './types'
import {
  getDetailAction,
  getListAction,
  updateAction,
  updateCustomerStatusAction,
  countAllTabAction,
  getReasonAction,
  getTaskLogsAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getAllCustomerTaskListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getBranchTaskList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

// export const getMeCustomerTaskListAPI = (data: ListReq, token: string, isAgentChatSale: boolean = false) => {
//   const endPoint = isAgentChatSale ? APIPath.getChatSaleCustomerTaskList : APIPath.getMeCustomerTaskList
//   return withAPIAction<ListReq>({
//     method: 'GET',
//     baseUrl: appConfig.apiEndpoint,
//     path: `${endPoint}?${getRequestString(data)}`,
//     data,
//     token,
//   })(getListAction)
// }

export const getAllCarloanDetailAPI = (data: DetailReq, token: string) => {
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getBranchTaskByID}?id=${data.id.toString()}`,
    data,
    token,
  })(getDetailAction)
}

// export const getMeCarloanDetailAPI = (data: DetailReq, token: string, isAgentChatSale: boolean = false) => {
//   const path = isAgentChatSale ? APIPath.chatSaleCarloanByID : APIPath.meCarloanByID
//   const endPoint = path.replace(':id', data.id.toString())
//   return withAPIAction<DetailReq>({
//     method: 'GET',
//     baseUrl: appConfig.apiEndpoint,
//     path: endPoint,
//     data,
//     token,
//   })(getDetailAction)
// }

export const updateCustomerTaskAPI = (data: UpdateReq, token: string) => {
  return withAPIAction<UpdateReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.branchUpdateTask,
    data,
    token,
  })(updateAction)
}

export const updateCustomerTaskStatusAPI = (data: UpdateStatusReq, token: string) => {
  return withAPIAction<UpdateStatusReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.branchUpdateTaskStatus,
    data,
    token,
  })(updateCustomerStatusAction)
}

export const countAllTaskAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.countAllTab,
    data,
    token,
  })(countAllTabAction)
}

export const getReasonListAPI = (data: ReasonReq, token: string) => {
  return withAPIAction<ReasonReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getBranchReasonList}?${getRequestString(data)}`,
    data,
    token,
  })(getReasonAction)
}

export const getTaskLogsAPI = (data: LogReq, token: string) => {
  const endPoint = APIPath.getBranchTaskLogs.replace(':id', data.id.toString())
  return withAPIAction<LogReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getTaskLogsAction)
}
