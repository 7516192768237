import { createStandardAction } from 'typesafe-actions'
import { ReciveMessageSocket, SelfeMessageSocket, User, UserSocket } from './types'

export const chatUserAction = createStandardAction('CHAT/CHAT_USER')<void>()

export const activeUserAction = createStandardAction('CHAT/ACTIVE_USER')<{
  userId: number
}>()

export const setFullUserAction = createStandardAction('CHAT/FULL_USER')<{
  fullUser: any
}>()

export const addLoggedinUserAction = createStandardAction('CHAT/ADD_LOGGED_USER')<{
  userData: any
}>()

export const createGroupAction = createStandardAction('CHAT/CREATE_GROUP')<{
  groupData: any
}>()

export const clearEventAction = createStandardAction('CHAT/CLEAR_EVENT')<void>()

export const friendListAction = createStandardAction('CHAT/INIT_FRIENDS')<{
  users: UserSocket[]
  uuid: string
  myAssignID: string
}>()

export const appendFriendAction = createStandardAction('CHAT/APPEND_FRIEND')<{
  user: UserSocket
}>()

export const disconnectedFriendAction = createStandardAction('CHAT/DISCONECTED_FRIEND')<{
  user: UserSocket
}>()

export const recieveChatFromAction = createStandardAction('CHAT/RECIEVE_MESSAGE')<{
  recieveMessage: ReciveMessageSocket
  uuid: string
}>()

export const selfChatFromAction = createStandardAction('CHAT/SELF_MESSAGE')<{
  recieveMessage: SelfeMessageSocket
}>()

export const consumerHistoryChatAction = createStandardAction('CHAT/CONSUMER_HISTORY_MESSAGE')<{
  messages: ReciveMessageSocket[]
}>()

export const agentHistoryChatAction = createStandardAction('CHAT/AGENT_HISTORY_MESSAGE')<{
  messages: ReciveMessageSocket[]
}>()

export const onTypingAction = createStandardAction('CHAT/TYPING_MESSAGE')<{
  user: User
}>()

export const onTypedAction = createStandardAction('CHAT/TYPED_MESSAGE')<{
  user: User
}>()

//handle layout
export const openUserSidebarAction = createStandardAction('CHAT/OPEN_USER_PROFILE_SIDEBAR')<void>()

export const closeUserSidebarAction = createStandardAction('CHAT/CLOSE_USER_PROFILE_SIDEBAR')<void>()

export const updateFriendName = createStandardAction('CHAT/UPDATE_FRIEND_NAME')<{
  name: string
  uuid: string
}>()

export const onBookedFriend = createStandardAction('CHAT/BOOKED_FRIEND')<{
  user: UserSocket
  uuid: string
}>()

export const onUpdateAgentQrAction = createStandardAction('CHAT/GET_AGENT_QR')<{
  user: UserSocket
}>()

export const onChangeAgent = createStandardAction('CHAT/CHANGE_AGENT')<{
  id: string
}>()
