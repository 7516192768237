import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, message, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  isFormRequired,
  isFormError,
  numberWithComma,
  getBranchTaskStatus,
  formatDateWithoutTime,
  getBranchReasonMenuSelect,
  isAfter,
  toDateQuery,
  formatDate,
} from 'helpers/format'
import { DetailReq, ReasonReq, UpdateReq, UpdateStatusReq } from 'Store/branch-task/types'
import {
  selectAssignTaskEvent,
  selectBranchList,
  selectDeliveryStaffList,
  selectDetail,
  selectDetailEvent,
  selectPostcodeList,
  selectReasonList,
  selectTaskEvent,
} from 'Store/branch-task/selector'
import { clearEventAction } from 'Store/branch-task/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmWithChild } from 'Components/modal'
import { BranchTaskStatus, ReasonType, SaleTaskStatus } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppDateItem, AppInput, AppSelect, MultiUploadForm } from 'Components/form'
import { StatusTag } from 'Components/utilities'
import { updateCustomerTaskStatusAPI, getReasonListAPI } from 'Store/branch-task/api'
import { CheckOutlined, CloseOutlined, EditOutlined, FileSyncOutlined, SaveOutlined } from '@ant-design/icons'
import Logs from './components/Logs'
import { getAllCarloanDetailAPI } from 'Store/branch-task/api'
import { TaskSaleData } from 'Store/branch-task/types'
import { selectBrandList, selectModelList, selectYearList } from 'Store/chaiyo-customer/selector'
import moment from 'moment'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
  isMe: boolean
  isAgentChatSale?: boolean
}

const CustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    detail,
    detailEvent,
    loginData,
    assignTaskEvent,
    reasonList,
  } = props

  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [closedDialog, setClosedDialog] = useState(false)
  const [cancelDialog, setCancelDialog] = useState(false)
  const [showLogDrawer, setShowLogDrawer] = useState(false)
  const [reasonSelected, setReasonSelected] = useState(0)
  const [reasonInput, setReasonInput] = useState('')
  const [approveAmtInput, setApproveAmtInput] = useState(0)
  const [approveDateInput, setApproveDateInput] = useState('')
  const [editMode, setEditMode] = useState(false)
  const editable = detail?.task_sale?.status === SaleTaskStatus.TOBRANCH
  // const [nextStatus, setNextStatus] = useState('' as BranchTaskStatus.TOBRANCH | BranchTaskStatus.TODELIVERY)
  const dispatch = useDispatch()
  const namalRequireFeild = ['reason_id']
  const appointmentRequireFeild = ['reason_id', 'appointment_date']
  const getReasonName = reasonList?.find(item => item?.id === formData?.reason_id)
  const isAppointment = getReasonName && getReasonName?.reason?.includes('นัดหมายลูกค้าเข้าสาขา')
  const requireFeild = isAppointment ? appointmentRequireFeild : namalRequireFeild

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && id) {
      onGetDetail()
      onGetReasonListAPI(ReasonType.SALE)
    }
  }, [visible])

  const initFormData = () => {
    const form = {
      reason: '',
      note: '',
      status: BranchTaskStatus.ONPROCESS,
      task_id: id,
    } as UpdateReq
    setFormData({ ...form })
  }

  useEffect(() => {
    if (id > 0) {
      initFormData()
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    dispatch(getAllCarloanDetailAPI(req, loginData?.access_token || ''))
  }

  const onGetReasonListAPI = (type: string) => {
    const req: ReasonReq = {
      reason_type: type,
    }
    dispatch(getReasonListAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (): void => {
    const getReasonName = reasonList?.find(item => item?.id === formData?.reason_id)
    const req: UpdateStatusReq = {
      ...formData,
      reason: getReasonName?.reason || '',
      reason_id: formData?.reason_id || 0,
      task_id: id,
      status: BranchTaskStatus.ONPROCESS,
    }
    if (formData?.appointment_date) {
      req.appointment_date = toDateQuery(req.appointment_date)
    }

    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: BranchTaskStatus, reason: number, note?: string): void => {
    // consoleLog('onUpdateStatus', status)
    const getReasonName = reasonList?.find(item => item?.id === reason)
    console.log('getReasonName', getReasonName)
    const req: UpdateStatusReq = {
      ...formData,
      reason: getReasonName?.reason || '',
      reason_id: reason,
      note: note || '',
      task_id: id,
      status,
    }
    if (status === BranchTaskStatus.CLOSED) {
      req.approved_amount = approveAmtInput
      req.approved_date = toDateQuery(approveDateInput)
    }

    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || ''))
  }

  // const onClose = (): void => {
  //   onUpdateStatus(BranchTaskStatus.CLOSED, '')
  // }

  const onSave = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    // setEditMode(false)
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    resetReasonForm()
    clearEvent()
    onGetDetail()
    setRejectDialog(false)
    setClosedDialog(false)
    setCancelDialog(false)
    onSuccess()
    setEditMode(false)
  }

  const onAssignEventSuccess = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const onAssignEventError = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const getFooter = (status?: SaleTaskStatus) => {
    const btn = [] as any[]
    if (!editMode && status === SaleTaskStatus.TOBRANCH) {
      btn.push(
        <PrimaryButton
          text="ปฏิเสธสินเชื่อ"
          icon={<CloseOutlined />}
          btnType="Reject"
          onClick={() => {
            onGetReasonListAPI(ReasonType.REJECTED)
            setRejectDialog(true)
          }}
        />,
      )
      btn.push(
        <PrimaryButton
          key={`${status}-approve`}
          text="อนุมัติสินเชื่อ"
          btnType="Approve"
          icon={<CheckOutlined />}
          onClick={() => {
            onGetReasonListAPI(ReasonType.CLOSED)
            setClosedDialog(true)
          }}
        />,
      )
      btn.push(
        <PrimaryButton
          key={`${status}-cancel`}
          text="ยกเลิกสินเชื่อ"
          btnType="Cancel"
          icon={<CloseOutlined />}
          onClick={() => {
            onGetReasonListAPI(ReasonType.CANCEL)
            setCancelDialog(true)
          }}
        />,
      )
    }
    return (
      <Space size="middle" align="baseline">
        {btn}
      </Space>
    )
  }

  const FooterContent = getFooter(detail?.task_sale?.status)

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  // const ConfirmApproveDailog = (
  //   <ConfirmDailog
  //     title={`ยืนยันส่งเคสไปดำเนินการ`}
  //     description={`คุณแน่ใจที่จะส่งเคสนี้ไปยัง${taskTypeName}หรือไม่?`}
  //     confirmBtnTxt="ยืนยัน"
  //     isShow={approveDialog}
  //     handleOk={onClose}
  //     handleCancel={() => setApproveDialog(false)}
  //     loading={event.IsLoading}
  //   />
  // )

  const getDailogApproveChild = () => {
    return (
      <Row>
        <Col md={24} xs={24}>
          <AppSelect
            name="reasonSelected"
            label="เหตุผล"
            placeHolder="กรุณาเลือกเหตุผล"
            isRequired={true}
            isError={false}
            value={reasonSelected}
            onChange={(val: number) => setReasonSelected(val)}
            menu={getBranchReasonMenuSelect(reasonList)}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="approveAmtInput"
            label="วงเงินที่อนุมัติ"
            isRequired={true}
            isError={false}
            value={approveAmtInput}
            onChange={setApproveAmtInput}
            type="number"
          />
        </Col>
        <Col md={24} xs={24}>
          <AppDateItem
            label="วันที่อนุมัติ"
            placeHolder="วันที่อนุมัติ"
            isRequired={true}
            isError={false}
            onChange={(date: string) => setApproveDateInput(date)}
            date={approveDateInput}
            allowClear={false}
            picker="date"
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="reasonInput"
            label="หมายเหตุ"
            isRequired={false}
            isError={false}
            value={reasonInput}
            onChange={setReasonInput}
          />
        </Col>
      </Row>
    )
  }

  const getDailogRejectChild = () => {
    return (
      <Row>
        <Col md={24} xs={24}>
          <AppSelect
            name="reasonSelected"
            label="เหตุผล"
            placeHolder="กรุณาเลือกเหตุผล"
            isRequired={true}
            isError={false}
            value={reasonSelected}
            onChange={(val: number) => setReasonSelected(val)}
            menu={getBranchReasonMenuSelect(reasonList)}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="reasonInput"
            label="หมายเหตุ"
            isRequired={false}
            isError={false}
            value={reasonInput}
            onChange={setReasonInput}
          />
        </Col>
      </Row>
    )
  }

  const resetReasonForm = () => {
    setReasonSelected(0)
    setReasonInput('')
    setApproveAmtInput(0)
    setApproveDateInput('')
  }

  const ConfirmRejectDailog = (
    <ConfirmWithChild
      title={'ยืนยันการปฏิเสธสินเชื่อ'}
      isShow={rejectDialog}
      handleOk={() => {
        if (!reasonSelected) {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(BranchTaskStatus.REJECTED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setRejectDialog(false)
      }}
      child={getDailogRejectChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmClosedDailog = (
    <ConfirmWithChild
      title={'ยืนยันการอนุมัติสินเชื่อ'}
      isShow={closedDialog}
      handleOk={() => {
        if (!reasonSelected) {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        if (!approveAmtInput) {
          message.error('กรุณาระบุวงเงินที่อนุมัติ')
          return
        }
        if (!approveDateInput) {
          message.error('กรุณาระบุวันที่อนุมติ')
          return
        }
        onUpdateStatus(BranchTaskStatus.CLOSED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setClosedDialog(false)
      }}
      child={getDailogApproveChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmCancelDailog = (
    <ConfirmWithChild
      title={'ยืนยันการยกเลิกสินเชื่อ'}
      isShow={cancelDialog}
      handleOk={() => {
        if (!reasonSelected) {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(BranchTaskStatus.CANCEL, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setCancelDialog(false)
      }}
      child={getDailogRejectChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const renderStatus = (value?: TaskSaleData) => {
    const result = getBranchTaskStatus(value?.status || '')
    // if (value?.status === BranchTaskStatus.CANCEL) {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Reason>เหตุผล : {value?.reason}</Reason>}
    //       {value?.note && <Reason>หมายเหตุ : {value?.note}</Reason>}
    //     </Space>
    //   )
    // } else {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Note>เหตุผล : {value?.reason}</Note>}
    //       {value?.note && <Note>หมายเหตุ : {value?.note}</Note>}
    //     </Space>
    //   )
    // }
    return <StatusTag color={result.color} text={result.text} />
  }

  const taskInfo = (
    <BoxWrapper title="รายละเอียดงาน">
      <Row justify="space-between" gutter={[1, 1]}>
        {renderInfoRow(renderInfo('หมายเลขเคส', detail?.ams_case_no), null)}
        {renderInfoRow(
          renderInfo(
            'Agent ที่สร้างเคส',
            `${detail?.user?.profile?.first_name}  ${detail?.user?.profile?.last_name}`,
          ),
          renderInfo('สถานะงาน', renderStatus(detail?.task_sale)),
        )}
        {renderInfoRow(
          renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')),
          detail?.task_sale?.reason ? renderInfo('เหตุผล', detail?.task_sale?.reason) : null,
        )}
        {renderInfoRow(
          null,
          detail?.task_sale?.note ? renderInfo('หมายเหตุ', detail?.task_sale?.note) : null,
        )}
        {renderInfoRow(renderInfo('QR ที่สแกนเข้ามา', detail?.qr_name || '-'), null)}
      </Row>
    </BoxWrapper>
  )

  const carInfo = (
    <BoxWrapper title="ข้อมูลรถยนต์">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ยี่ห้อรถยนต์', detail?.car_loan?.car_brand || ''),
          renderInfo('รุ่นรถ', detail?.car_loan?.car_model),
        )}
        {renderInfoRow(
          renderInfo('ปีที่จดทะเบียน', detail?.car_loan?.car_year),
          renderInfo('ราคาประเมิน', `${numberWithComma(detail?.car_loan?.amount_redbook, true)} บาท`),
        )}
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => {
          onGetReasonListAPI(ReasonType.SALE)
          setEditMode(true)
        }}
      />
    )
  }

  const saveButton = () => {
    return (
      <Space>
        <PrimaryButton
          text="ยกเลิก"
          btnType="Cancel"
          onClick={() => {
            initFormData()
            setEditMode(false)
          }}
        />
        <PrimaryButton text="บันทึกข้อมูล" icon={<SaveOutlined />} onClick={onSave} />
      </Space>
    )
  }

  const documentInfo = (
    <BoxWrapper
      title="ข้อมูลรูปภาพและเอกสารรถยนต์"
      // right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.task_sale?.documents || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const updateForm = () => {
    return (
      <Row gutter={[16, 16]} justify="start">
        <Col md={12} xs={24}>
          <AppSelect
            name="reason_id"
            label="เหตุผล"
            isRequired={isRequired('reason_id')}
            isError={isError('reason_id')}
            value={formData.reason_id}
            onChange={onUpdateData}
            menu={getBranchReasonMenuSelect(reasonList)}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="note"
            label="หมายเหตุ"
            isRequired={isRequired('note')}
            isError={isError('note')}
            value={formData.note}
            onChange={onUpdateData}
          />
        </Col>
        {isAppointment && (
          <Col md={12} xs={24}>
            <AppDateItem
              label="วันที่นัดหมายเข้าสาขา"
              placeHolder="วันที่นัดหมายเข้าสาขา"
              isRequired={isRequired('appointment_date')}
              isError={isError('appointment_date')}
              onChange={(date: string) => onUpdateData(date, 'appointment_date')}
              date={formData.appointment_date}
              allowClear={false}
              picker="date"
              disableDate={(date: any) => {
                return isAfter(
                  moment(date)
                    .subtract(-1, 'days')
                    .startOf('day')
                    .toString(),
                )
              }}
            />
          </Col>
        )}
      </Row>
    )
  }

  const toBranchInfo = (
    <BoxWrapper
      title="ข้อมูลสำหรับสาขา"
      //right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          {renderInfo('สาขาที่ลูกค้าต้องการเข้าดำเนินการ', detail?.task_sale?.branch_customer || '')}
        </Col>
        <Col md={12} xs={24}>
          {renderInfo(
            'วันที่ลูกค้าต้องการเข้า',
            formatDateWithoutTime(detail?.task_sale?.branch_customer_date || ''),
          )}
        </Col>
        <Col md={12} xs={24}>
          {renderInfo('เหตุผล', detail?.task_sale?.branch_customer_reason || '')}
        </Col>
        <Col md={12} xs={24}>
          {renderInfo('หมายเหตุ', detail?.task_sale?.branch_customer_note || '')}
        </Col>
      </Row>
    </BoxWrapper>
  )

  const historyButton = (
    <PrimaryButton
      text="ประวัติการอัปเดต"
      icon={<FileSyncOutlined />}
      btnType="Cancel"
      onClick={() => setShowLogDrawer(true)}
    />
  )

  const branchUpdateForm = (
    <BoxWrapper
      title="สาขาอัปเดตข้อมูลเคส"
      right={
        editable ? (
          !editMode ? (
            <Space>
              {editButton()}
              {historyButton}
            </Space>
          ) : (
            saveButton()
          )
        ) : (
          historyButton
        )
      }
    >
      <br />
      <Row gutter={[16, 16]} justify="start">
        {editMode ? (
          <Col md={24} xs={24}>
            {updateForm()}
          </Col>
        ) : (
          <Col md={24} xs={24}>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col md={12} xs={24}>
                {renderInfo('เหตุผล', detail?.task_branch?.reason || '')}
              </Col>
              {detail?.task_branch?.appointment_date && (
                <Col md={12} xs={24}>
                  {renderInfo(
                    'วันที่นัดหมายเข้าสาขา',
                    formatDateWithoutTime(detail?.task_branch?.appointment_date || ''),
                  )}
                </Col>
              )}
              {detail?.task_branch?.approved_amount && (
                <Col md={12} xs={24}>
                  {renderInfo(
                    'อนุมัติวงเงิน',
                    numberWithComma(detail?.task_branch?.approved_amount || 0) + ' บาท',
                  )}
                </Col>
              )}
              {detail?.task_branch?.approved_date && (
                <Col md={12} xs={24}>
                  {renderInfo(
                    'วันที่อนุมัติ',
                    formatDateWithoutTime(detail?.task_branch?.approved_date || ''),
                  )}
                </Col>
              )}
              <Col md={12} xs={24}>
                {renderInfo('หมายเหตุ', detail?.task_branch?.note || '')}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </BoxWrapper>
  )

  const customerInfo = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ชื่อ', detail?.first_name || ''),
          renderInfo('นามสกุล', detail?.last_name || ''),
        )}
        {renderInfoRow(
          renderInfo('เบอร์โทรศัพท์', detail?.mobile || ''),
          renderInfo('ที่อยู่', detail?.car_loan?.address || ''),
        )}
        {renderInfoRow(
          renderInfo(
            'วงเงินที่ลูกค้าต้องการ',
            `${numberWithComma(detail?.car_loan?.amount_limit || 0, true)} บาท`,
          ),
          renderInfo(
            'อัตราผ่อนที่ชำระได้ /เดือน',
            `${numberWithComma(detail?.car_loan?.amount_rate || 0, true)} บาท`,
          ),
        )}
        {renderInfoRow(
          renderInfo('สีรถ', detail?.car_loan?.car_paint || ''),
          renderInfo('ทะเบียน', detail?.car_loan?.car_registration || ''),
        )}
         {renderInfoRow(
          renderInfo('จังหวัดของทะเบียน', detail?.car_loan?.registration_province || ''),
          ''
        )}
        {renderInfoRow(
          renderInfo('สถาบันการเงินเดิม', detail?.car_loan?.financial_institution || ''),
          renderInfo('ยอดปิดบัญชีไฟแนนซ์เดิม', detail?.car_loan?.finance_account_closing || ''),
        )}
        {renderInfoRow(renderInfo('หมายเหตุ', detail?.car_loan?.note || ''), null)}
        {/* <Col span={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col> */}
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <MyTitle>
          หมายเลขเคส : {detail?.ams_case_no} ({detail?.first_name} {detail?.last_name})
        </MyTitle>
      </div>
    </Space>
  )

  const LogsDrawer = <Logs isShow={showLogDrawer} onClose={() => setShowLogDrawer(false)} id={id} />

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <EventModal
        event={[assignTaskEvent]}
        enable={true}
        successCalback={onAssignEventSuccess}
        errorCalback={onAssignEventError}
      />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        <Col md={24} xs={24}>
          {toBranchInfo}
        </Col>
        <Col md={24} xs={24}>
          {carInfo}
        </Col>
        <Col span={24}> {customerInfo}</Col>
        <Col md={24} xs={24}>
          {documentInfo}
        </Col>
        <Col md={24} xs={24}>
          {branchUpdateForm}
        </Col>
      </Row>
      {ConfirmRejectDailog}
      {ConfirmClosedDailog}
      {ConfirmCancelDailog}
      {LogsDrawer}
    </DetailDrawer>
  )
}

const MyTitle = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    assignTaskEvent: selectAssignTaskEvent(state),
    branchList: selectBranchList(state),
    brandList: selectBrandList(state),
    modelList: selectModelList(state),
    yearList: selectYearList(state),
    reasonList: selectReasonList(state),
    postcodeList: selectPostcodeList(state),
    staffList: selectDeliveryStaffList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTaskDetail)
