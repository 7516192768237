import { CreateMapping } from 'Store/common/common.types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  ActionRes,
  UpdateReq,
  UpdateStatusReq,
  UpdateQrAgentImageReq,
  CreateReq,
  AgentStructureReq,
  AgentStructureRes,
  AgentCodeReq,
  AgentCodeRes,
  AgentInfoReq,
  AgentInfoRes,
  ProfileReq,
  ProfileRes,
  AgenciesReq,
  AgenciesRes,
} from './types'

export const createAction = createAsyncAction(
  'AGENT/CREATE_AGENT_REQ',
  'AGENT/CREATE_AGENT_SUCCESS',
  'AGENT/CREATE_AGENT_FAILURE',
)<CreateReq, ActionRes, Error>()

export const getListAction = createAsyncAction(
  'AGENT/GET_AGENT_LIST_REQ',
  'AGENT/GET_AGENT_LIST_SUCCESS',
  'AGENT/GET_AGENT_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'AGENT/GET_AGENT_DETAIL_REQ',
  'AGENT/GET_AGENT_DETAIL_SUCCESS',
  'AGENT/GET_AGENT_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_REQ',
  'AGENT/UPDATE_AGENT_SUCCESS',
  'AGENT/UPDATE_AGENT_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateQrAgentStatusAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_STATUS_REQ',
  'AGENT/UPDATE_AGENT_STATUS_SUCCESS',
  'AGENT/UPDATE_AGENT_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const updateQrAgentImageAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_IMAGE_REQ',
  'AGENT/UPDATE_AGENT_IMAGE_SUCCESS',
  'AGENT/UPDATE_AGENT_IMAGE_FAILURE',
)<UpdateQrAgentImageReq, ActionRes, Error>()

export const getAgentStructerAction = createAsyncAction(
  'AGENT/GET_AGENT_STRUCTER_REQ',
  'AGENT/GET_AGENT_STRUCTER_SUCCESS',
  'AGENT/GET_AGENT_STRUCTER_FAILURE',
)<AgentStructureReq, AgentStructureRes, Error>()

export const getAgentCodeAction = createAsyncAction(
  'AGENT/GET_AGENT_CODES_REQ',
  'AGENT/GET_AGENT_CODES_SUCCESS',
  'AGENT/GET_AGENT_SCODES_FAILURE',
)<AgentCodeReq, AgentCodeRes, Error>()

export const getAgentInfoAction = createAsyncAction(
  'AGENT/GET_AGENT_INFO_REQ',
  'AGENT/GET_AGENT_INFO_SUCCESS',
  'AGENT/GET_AGENT_INFO_FAILURE',
)<AgentInfoReq, AgentInfoRes, Error>()

export const getAgentProfileAction = createAsyncAction(
  'AGENT/GET_AGENT_PROFILE_REQ',
  'AGENT/GET_AGENT_PROFILE_SUCCESS',
  'AGENT/GET_AGENT_PROFILE_FAILURE',
)<ProfileReq, ProfileRes, Error>()

export const getAgenciesAction = createAsyncAction(
  'AGENT/GET_AGENTCIES_REQ',
  'AGENT/GET_AGENTCIES_SUCCESS',
  'AGENT/GET_AGENTCIES_FAILURE',
)<AgenciesReq, AgenciesRes, Error>()

export const clearEventAction = createStandardAction('AGENT/CLEAR_EVENT')<void>()
