import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import {
  BranchData,
  CountData,
  Data,
  DeliveryStaffData,
  ListData,
  LogData,
  PostcodeData,
  BranchReasonData,
} from './types'

export const selectAssignTaskEvent = (state: RootState): Event => state.branchTaskReducer.assignTaskEvent

export const selectList = (state: RootState): ListData => state.branchTaskReducer.customerList.data

export const selectListEvent = (state: RootState): Event => state.branchTaskReducer.customerList.event

export const selectDetail = (state: RootState): Data | undefined =>
  state.branchTaskReducer.customerDetail.detail

export const selectDetailEvent = (state: RootState): Event => state.branchTaskReducer.customerDetail.event

export const selectAllTabCountData = (state: RootState): CountData => state.branchTaskReducer.allTabCountData

export const selectTaskEvent = (state: RootState): Event => state.branchTaskReducer.taskEvent

export const selectBranchList = (state: RootState): BranchData[] => state.branchTaskReducer.branchData.data

export const selectReasonList = (state: RootState): BranchReasonData[] =>
  state.branchTaskReducer.reasonData.data

export const selectPostcodeList = (state: RootState): PostcodeData[] =>
  state.branchTaskReducer.postcodeData.data

export const selectDeliveryStaffList = (state: RootState): DeliveryStaffData[] =>
  state.branchTaskReducer.deliveryStaffData.data

export const selectLogsData = (state: RootState): LogData[] => state.branchTaskReducer.logData.data

export const selectLogsEvent = (state: RootState): Event => state.branchTaskReducer.logData.event
