import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { uniqeID } from 'helpers/format'

interface Props {
  columns: any
  datas: any[]
  isLoading?: boolean
  paginationContent?: any
  rowClassName?: any
  scrollX?: number
  size?: SizeType
  onRow?: any
  offsetHeader?: number
  bordered?: boolean
  style?: any
  expandable?: any
  footer?: any
  rowSelection?: any
  rowKey?: string
}
const TableListPage = (props: Props): any => {
  const {
    columns,
    datas,
    isLoading = false,
    paginationContent,
    rowClassName,
    scrollX = 1100,
    size = 'middle',
    onRow = () => {},
    offsetHeader = -20,
    bordered = false,
    style,
    expandable,
    footer,
    rowSelection = null,
    rowKey = 'id',
  } = props

  return (
    <ContentWrapper>
      <TableStyled
        size={size}
        columns={columns}
        dataSource={datas}
        scroll={{ x: scrollX }}
        pagination={false}
        sticky={{ offsetHeader: offsetHeader }}
        loading={isLoading}
        rowClassName={rowClassName}
        onRow={onRow}
        rowKey={(record: any) => (record && record[rowKey]) || ''}
        bordered={bordered}
        style={style}
        expandable={expandable}
        footer={footer}
        rowSelection={rowSelection}
      />
      {paginationContent && <PaginationWrapper>{paginationContent}</PaginationWrapper>}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  margin: 20px 0;
  padding: 5px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border-radius: 5px;
  td {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  }
  .ant-table-thead > tr > th {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  }
`

const PaginationWrapper = styled.div`
  padding: 20px 5px;
`

const TableStyled = styled(Table)`
  .ant-table-column-sorters-with-tooltip {
    text-align: center;
  }
`

export default TableListPage
