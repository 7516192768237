import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { BranchData, CountData, Data, DeliveryStaffData, ListData, PostcodeData, ProvinceData, ReasonData } from './types'

export const selectAssignTaskEvent = (state: RootState): Event => state.saleTaskReducer.assignTaskEvent

export const selectList = (state: RootState): ListData => state.saleTaskReducer.customerList.data

export const selectListEvent = (state: RootState): Event => state.saleTaskReducer.customerList.event

export const selectDetail = (state: RootState): Data | undefined =>
  state.saleTaskReducer.customerDetail.detail

export const selectDetailEvent = (state: RootState): Event => state.saleTaskReducer.customerDetail.event

export const selectAllTabCountData = (state: RootState): CountData => state.saleTaskReducer.allTabCountData

export const selectMeTabCountData = (state: RootState): CountData => state.saleTaskReducer.meTabCountData

export const selectTaskEvent = (state: RootState): Event => state.saleTaskReducer.taskEvent

export const selectBranchList = (state: RootState): BranchData[] => state.saleTaskReducer.branchData.data

export const selectReasonList = (state: RootState): ReasonData[] => state.saleTaskReducer.reasonData.data

export const selectPostcodeList = (state: RootState): PostcodeData[] =>
  state.saleTaskReducer.postcodeData.data

export const selectDeliveryStaffList = (state: RootState): DeliveryStaffData[] =>
  state.saleTaskReducer.deliveryStaffData.data

export const selectProvinceList = (state: RootState): ProvinceData[] =>
  state.saleTaskReducer.provinceData.data