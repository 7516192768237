import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ActionRes,
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  CountRes,
  BranchReq,
  BranchRes,
  ReasonReq,
  ReasonRes,
  AssignReq,
  PostcodeReq,
  PostcodeRes,
  DeliveryStaffReq,
  DeliveryStaffRes,
  LogReq,
  LogRes,
} from './types'

export const getListAction = createAsyncAction(
  'BRANCH_TASK/GET_BRANCH_TASK_LIST_REQ',
  'BRANCH_TASK/GET_BRANCH_TASK_LIST_SUCCESS',
  'BRANCH_TASK/GET_BRANCH_TASK_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'BRANCH_TASK/GET_BRANCH_TASK_DETAIL_REQ',
  'BRANCH_TASK/GET_BRANCH_TASK_DETAIL_SUCCESS',
  'BRANCH_TASK/GET_BRANCH_TASK_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'BRANCH_TASK/UPDATE_BRANCH_TASK_REQ',
  'BRANCH_TASK/UPDATE_BRANCH_TASK_SUCCESS',
  'BRANCH_TASK/UPDATE_BRANCH_TASK_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateCustomerStatusAction = createAsyncAction(
  'BRANCH_TASK/UPDATE_BRANCH_TASK_STATUS_REQ',
  'BRANCH_TASK/UPDATE_BRANCH_TASK_STATUS_SUCCESS',
  'BRANCH_TASK/UPDATE_BRANCH_TASK_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const assignTaskAction = createAsyncAction(
  'BRANCH_TASK/ASSIGN_BRANCH_TASK_REQ',
  'BRANCH_TASK/ASSIGN_BRANCH_TASK_SUCCESS',
  'BRANCH_TASK/ASSIGN_BRANCH_TASK_FAILURE',
)<AssignReq, ActionRes, Error>()

export const countAllTabAction = createAsyncAction(
  'BRANCH_TASK/COUNT_ALL_TASK_TAB_REQ',
  'BRANCH_TASK/COUNT_ALL_TASK_TAB_SUCCESS',
  'BRANCH_TASK/COUNT_ALL_TASK_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const getBranchListAction = createAsyncAction(
  'BRANCH_TASK/GET_BRANCH_LIST_REQ',
  'BRANCH_TASK/GET_BRANCH_LIST_SUCCESS',
  'BRANCH_TASK/GET_BRANCH_LIST_FAILURE',
)<BranchReq, BranchRes, Error>()

export const getReasonAction = createAsyncAction(
  'BRANCH_TASK/GET_REASON_LIST_REQ',
  'BRANCH_TASK/GET_REASON_LIST_SUCCESS',
  'BRANCH_TASK/GET_REASON_LIST_FAILURE',
)<ReasonReq, ReasonRes, Error>()

export const getPostcodeListAction = createAsyncAction(
  'BRANCH_TASK/GET_POSTCODE_LIST_REQ',
  'BRANCH_TASK/GET_POSTCODE_LIST_SUCCESS',
  'BRANCH_TASK/GET_POSTCODE_LIST_FAILURE',
)<PostcodeReq, PostcodeRes, Error>()

export const getStaffListAction = createAsyncAction(
  'BRANCH_TASK/GET_STAFF_LIST_REQ',
  'BRANCH_TASK/GET_STAFF_LIST_SUCCESS',
  'BRANCH_TASK/GET_STAFF_LIST_FAILURE',
)<DeliveryStaffReq, DeliveryStaffRes, Error>()

export const getTaskLogsAction = createAsyncAction(
  'BRANCH_TASK/GET_TASK_LOGS_REQ',
  'BRANCH_TASK/GET_TASK_LOGS_SUCCESS',
  'BRANCH_TASK/GET_TASK_LOGS_FAILURE',
)<LogReq, LogRes, Error>()

export const clearEventAction = createStandardAction('BRANCH_TASK/CLEAR_EVENT')<void>()
