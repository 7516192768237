import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect } from 'react-redux'
import { RootState } from 'Store'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import Iframe from 'react-iframe'
import styled from 'styled-components'
import { consoleLog } from 'helpers/utils'

type Props = {} & RouteComponentProps & ReduxProps

const DashboardIframe: React.FunctionComponent<Props> = props => {
  const { userProfile } = props
  const url = `https://app.powerbi.com/view?${userProfile?.dashboard_url?.report_id}`
  consoleLog('url-->', url)
  //const url = `https://app.powerbi.com/reportEmbed?reportId=${userProfile?.dashboard_url?.report_id}&autoAuth=true&ctid=2f57d4db-9955-48f4-998c-0f3fc5338f8f`
  return (
    <HomePageLayout selected="amp-dashboard" title={`AMP Dashboard`} label={''}>
      <WebIframe
        title={`Agent Monitor Dashboard ${userProfile?.dashboard_url?.agent_code}`}
        url={url}
        height="90%"
        id=""
        className=""
        display="block"
        position="relative"
      />
    </HomePageLayout>
  )
}

const WebIframe = styled(Iframe)`
  width: 100%;
`

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(DashboardIframe)
