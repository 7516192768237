import React, { useEffect } from 'react'
import { Card, Col, Empty, Row, Timeline } from 'antd'
import { DetailDrawer } from 'Components/common/MainLayout'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { LoadingView } from 'Components/modal'
import { MyTheme } from 'Components/common/Theme'
import {
  formatDateTime,
  formatDateWithoutTime,
  getBranchTaskStatus,
  getTaskLogsStatus,
  numberWithComma,
} from 'helpers/format'
import Label from 'Components/text/Label'
import { getTaskLogsAPI } from 'Store/chaiyo-customer/api'
import { LogData, LogReq } from 'Store/chaiyo-customer/types'
import { selectLoginData } from 'Store/user/selector'
import { selectLogsData, selectLogsEvent } from 'Store/chaiyo-customer/selector'
import { CustomerStatus, SaleTaskStatus } from 'helpers/constanst'
import { InfoItem } from 'Components/text'

type Props = {
  isShow: boolean
  onClose: () => void
  id: number
} & ReduxProps

const Logs = (props: Props) => {
  const { isShow, onClose, id, loginData, list, event } = props

  const dispatch = useDispatch()

  const onGetDataList = (): void => {
    const req: LogReq = {
      id: id,
    }
    dispatch(getTaskLogsAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (isShow) {
      onGetDataList()
    }
  }, [isShow, id])

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const getBranchLog = (item: LogData, index: number) => {
    const info = item.information || '{}'
    const jsonInfo = JSON.parse(info)
    const status = getBranchTaskStatus(item.status)
    return (
      <Timeline.Item key={index} dot={<StartDot style={{ backgroundColor: status.color }} />}>
        {item.user_type.toUpperCase()} : {status.text}
        <br />
        <LabelStyle>วัน/เวลา : {formatDateTime(item.created_at)}</LabelStyle> <br />
        <LabelStyle>โดย : {item.user_fullname}</LabelStyle>
        <MyCard style={{ width: '100%', margin: '10px 0' }}>
          <div>รายละเอียด</div>
          {renderInfo('เหตุผล', `${jsonInfo?.reason || '-'}`)}
          {renderInfo('หมายเหตุ', `${jsonInfo?.note || '-'}`)}
          {jsonInfo?.appointment_date &&
            renderInfo('วันที่นัดหมายเข้าสาขา', formatDateWithoutTime(jsonInfo?.appointment_date || ''))}
          {jsonInfo?.approved_amount > 0 &&
            renderInfo('อนุมัติวงเงิน', numberWithComma(jsonInfo?.approved_amount || 0) + ' บาท')}
          {jsonInfo?.approved_date &&
            renderInfo('วันที่อนุมัติ', formatDateWithoutTime(jsonInfo?.approved_date || ''))}
        </MyCard>
      </Timeline.Item>
    )
  }

  const getAgentSaleLog = (item: LogData, index: number) => {
    const info = item.information || '{}'
    const jsonInfo = JSON.parse(info)
    const status = getTaskLogsStatus(item.status)
    return (
      <Timeline.Item key={index} dot={<StartDot style={{ backgroundColor: status.color }} />}>
        {item.user_type.toUpperCase()} : {status.text}
        <br />
        <LabelStyle>วัน/เวลา : {formatDateTime(item.created_at)}</LabelStyle> <br />
        <LabelStyle>โดย : {item.user_fullname}</LabelStyle>
        {(item.status === CustomerStatus.CLOSE || item.status === SaleTaskStatus.REJECTED) &&
          item.information && (
            <MyCard style={{ width: '100%', margin: '10px 0' }}>
              <div>รายละเอียด</div>
              {renderInfo('เหตุผล', `${jsonInfo?.reason || '-'}`)}
              {renderInfo('หมายเหตุ', `${jsonInfo?.note || '-'}`)}
            </MyCard>
          )}
        {(item.status === 'to_branch' || item.status === 'edit' || item.status === 'to_delivery') &&
          item.information && (
            <MyCard style={{ width: '100%', margin: '10px 0' }}>
              <div>
                รายละเอียด
                {jsonInfo?.task_type === SaleTaskStatus.TODELIVERY
                  ? ' (ข้อมูลสำหรับส่งเคสไปยัง Delivery)'
                  : ' (ข้อมูลสำหรับส่งเคสไปยังสาขา)'}
              </div>
              {jsonInfo?.task_type === SaleTaskStatus.TODELIVERY ? (
                <>
                  {renderInfo(
                    'รหัสไปรษณีย์ ของจุดให้บริการ',
                    `${jsonInfo?.delivery_customer_postcode || '-'}`,
                  )}
                  {renderInfo(
                    'รายชื่อตัวแทนที่เข้าดำเนินการ',
                    `${jsonInfo?.delivery_customer_staff_id || '-'} -
                        ${jsonInfo?.delivery_customer_name || ''}`,
                  )}
                  {renderInfo(
                    'วันที่นัดหมาย',
                    `${
                      jsonInfo?.delivery_customer_date
                        ? formatDateWithoutTime(jsonInfo?.delivery_customer_date)
                        : '-'
                    }`,
                  )}
                  {renderInfo('เหตุผล', `${jsonInfo?.delivery_customer_reason || '-'}`)}
                  {renderInfo('หมายเหตุ', `${jsonInfo?.delivery_customer_note || '-'}`)}
                </>
              ) : (
                <>
                  {renderInfo('สาขาที่ลูกค้าต้องการเข้าดำเนินการ', `${jsonInfo?.branch_customer || '-'}`)}
                  {renderInfo(
                    'วันที่ลูกค้าต้องการเข้า',
                    `${
                      jsonInfo?.branch_customer_date
                        ? formatDateWithoutTime(jsonInfo?.branch_customer_date)
                        : '-'
                    }`,
                  )}
                  {renderInfo('เหตุผล', `${jsonInfo?.branch_customer_reason || '-'}`)}
                  {renderInfo('หมายเหตุ', `${jsonInfo?.branch_customer_note || '-'}`)}
                </>
              )}
            </MyCard>
          )}
      </Timeline.Item>
    )
  }

  const getTimeLines = () => {
    const timeline = [] as any
    list &&
      list.map((item: LogData, index: number) => {
        timeline.unshift(
          item.user_type === 'branch' ? getBranchLog(item, index) : getAgentSaleLog(item, index),
        )
      })
    return timeline
  }

  return (
    <DetailDrawer
      title={'สถานะงาน'}
      width={'50%'}
      onCloseDrawer={onClose}
      visible={isShow}
      footer={null}
      maskClosable={true}
    >
      <LoadingView isShow={event.IsLoading} />
      <Row>
        <Col span={24}>
          {list && list.length > 0 ? (
            <Timeline>{getTimeLines()}</Timeline>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Col>
      </Row>
    </DetailDrawer>
  )
}

const LabelStyle = styled(Label)`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const MyCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
  }
`

const StartDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectLogsEvent(state),
    list: selectLogsData(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Logs)
