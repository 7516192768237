import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import Theme, { MyTheme } from 'Components/common/Theme'
import { Card, Col, message, Row, Space, Statistic } from 'antd'
import { PrimaryButton } from 'Components/button'
import { formValidation, isFormRequired, isFormError, numberWithComma, getMenuSelect } from 'helpers/format'
import { clearEventAction } from 'Store/chaiyo-customer/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog } from 'Components/modal'
import { CustomerStatus, SaleTaskStatus } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppInput, AppSelect, AppTextArea, MultiUploadForm } from 'Components/form'
import {
  selectBrandList,
  selectModelList,
  selectYearList,
  selectActionEvent,
} from 'Store/chaiyo-customer/selector'
import { BrandReq, BrandSlugReq, YearData, UpdateCustomerReq } from 'Store/chaiyo-customer/types'
import { getBrandBySlugListAPI, getBrandListAPI, updateCustomerAPI } from 'Store/chaiyo-customer/api'
import { getYearByModelDataAction, resetBrandDataAction } from 'Store/chaiyo-customer/actions'
import { appConfig } from 'config'
import { SearchOutlined } from '@ant-design/icons'
import { consoleLog } from 'helpers/utils'
import { selectProvinceList } from 'Store/sale-task/selector'
import { getProvinceListAPI } from 'Store/sale-task/api'
import { ProvinceReq } from 'Store/sale-task/types'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
}

const CreateCustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    loginData,
    brandList,
    modelList,
    yearList,
    provinceList,
  } = props

  consoleLog('provinceList',provinceList)

  const requireFeild = [
    'first_name',
    'last_name',
    'mobile',
    //'address',
    'car_brand',
    'car_model',
    'car_year',
    'amount_redbook',
    'amount_limit',
    'amount_rate',
    'car_registration',
    'registration_province'
  ]
  const initForm = {} as UpdateCustomerReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [activeYear, setActiveYear] = useState(undefined as YearData | undefined)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
      initFormData()
    }
  }, [visible])

  const initFormData = () => {
    const form = {
      first_name: '',
      last_name: '',
      address: '',
      amount_limit: 0,
      amount_rate: 0,
      amount_redbook: 0,
      car_paint: '',
      car_registration: '',
      images: [],
      mobile: '',
      car_brand: '',
      car_model: '',
      car_year: '',
      qr_reference: '',
      status: CustomerStatus.OPEN,
      finance_account_closing: '',
      financial_institution: '',
      note: '',
      registration_province: '',
    } as UpdateCustomerReq
    setFormData({ ...form })
  }

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onUpdate = (): void => {
    setConfirmDialog(false)
    const req: UpdateCustomerReq = {
      ...formData,
      qr_reference: '',
    }
    dispatch(updateCustomerAPI(req, 'manual-client', loginData?.access_token || ''))
  }

  const onSave = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const onGetBrandList = (): void => {
    const req: BrandReq = {}
    dispatch(getBrandListAPI(req, loginData?.access_token || ''))
  }

  const onGetProvinceList = (): void => {
    const req: ProvinceReq = {}
    dispatch(getProvinceListAPI(req, loginData?.access_token || ''))
  }

  const onGetBrandBySlugList = (slug: string): void => {
    const req: BrandSlugReq = {
      slug,
    }
    dispatch(getBrandBySlugListAPI(req, loginData?.access_token || ''))
  }

  const getYearData = (): void => {
    dispatch(getYearByModelDataAction({ model: formData.car_model || '' }))
  }

  const resetData = (): void => {
    formData.car_model = ''
    formData.car_year = ''
    dispatch(resetBrandDataAction())
  }

  useEffect(() => {
    if (visible) {
      onGetBrandList()
      onGetProvinceList()
    }
  }, [id, visible])

  useEffect(() => {
    if (formData.car_brand) {
      onGetBrandBySlugList(formData.car_brand || '')
    } else {
      resetData()
    }
  }, [formData.car_brand])

  useEffect(() => {
    if (formData.car_model) {
      getYearData()
    }
  }, [formData.car_model, modelList])

  useEffect(() => {
    if (!visible) return
    if (formData.car_year && formData.car_model) {
      const year = yearList && yearList.find((item: YearData) => item.description === formData.car_year)
      setActiveYear(year)
    } else {
      setActiveYear(undefined)
    }
  }, [formData.car_year, yearList, id, visible])

  const onUpdateCarBrand = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_model: '',
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarYear = (value: any, key: string) => {
    const year = yearList && yearList.find((item: YearData) => item.year === value)
    setFormData({
      ...formData,
      [key]: value,
      amount_redbook: parseFloat(year?.price || ''),
    })
  }

  const onUpdateCarModel = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarPrice = (value: any) => {
    setFormData({
      ...formData,
      amount_redbook: parseFloat(value || 0),
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    consoleLog('error', error)
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    setConfirmDialog(false)
    onSuccess()
  }

  const valStyle = {
    color: Theme.colors.black,
    fontFamily: Theme.fontFamily.regular,
    fontSize: Theme.typography.body4,
  }

  const renderPriceChoices = (key: string, name: string) => {
    return (
      <div onClick={() => onUpdateCarPrice(activeYear?.[key] || 0)}>
        <CardPrice className={formData.amount_redbook == (activeYear?.[key] || 0) ? 'actived' : ''}>
          <Statistic
            title={name}
            value={activeYear?.[key] || 0}
            suffix={'บาท'}
            valueStyle={valStyle}
            formatter={(val: any) => numberWithComma(parseFloat(val) || 0)}
          />
        </CardPrice>
      </div>
    )
  }

  const getFooter = () => {
    const btn = [] as any[]
    btn.push(
      <PrimaryButton
        key={`${status}-approve`}
        text="ปิด"
        btnType="Cancel"
        onClick={() => {
          onCloseDrawer()
        }}
      />,
    )
    btn.push(
      <PrimaryButton
        key={`${status}-approve`}
        text="ยืนยันการสร้าง"
        btnType="Primary"
        onClick={() => {
          if (!validateForm()) {
            message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
            return
          }
          setConfirmDialog(true)
        }}
      />,
    )

    return (
      <Space size="middle" align="baseline">
        {btn}
      </Space>
    )
  }

  const FooterContent = getFooter()

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันการสร้าง"
      description="คุณแน่ใจที่จะเพิ่มข้อมูลลูกค้านี้หรือไม่?"
      confirmBtnTxt="ยืนยัน"
      isShow={confirmDialog}
      handleOk={onSave}
      handleCancel={() => setConfirmDialog(false)}
      loading={event.IsLoading}
    />
  )

  const getExternalLink = () => {
    return (
      <ExternalLink href={appConfig.externalChaiyoLink} target="_blank" rel="noopener noreferrer">
        &nbsp;
        <SearchOutlined /> ตรวจสอบข้อมูลสาขา, ราคาประเมิน, คำนวนค่างวด
      </ExternalLink>
    )
  }

  const carForm = (
    <BoxWrapper title="ข้อมูลรถยนต์">
      <Row justify="start" align="top" gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_brand"
            label="ยี่ห้อรถ"
            isRequired={isRequired('car_brand')}
            isError={isError('car_brand')}
            value={formData.car_brand}
            onChange={onUpdateCarBrand}
            menu={getMenuSelect(brandList, 'name', 'slug')}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_model"
            label="รุ่นรถ"
            isRequired={isRequired('car_model')}
            isError={isError('car_model')}
            value={formData.car_model}
            onChange={onUpdateCarModel}
            menu={getMenuSelect(modelList, 'name', 'name')}
            disabled={formData.car_brand == ''}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_year"
            label="ปีจดทะเบียนรถ"
            isRequired={isRequired('car_year')}
            isError={isError('car_year')}
            value={formData.car_year}
            onChange={onUpdateCarYear}
            menu={getMenuSelect(yearList, 'description', 'description')}
            disabled={formData.car_model == ''}
            allowClear={false}
          />
        </Col>
        {activeYear && (
          <Col md={12} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <CarName> เลือก Range ราคา Redbook : {activeYear?.description || ''} </CarName>
              </Col>
              <Col md={24} xs={24}>
                {/* {renderPriceChoices('price', 'ราคา')} */}
                {renderPriceChoices('l1', 'ราคา L1')}
                {renderPriceChoices('l2', 'ราคา L2')}
                {/* {renderPriceChoices('l3', 'ราคา L3')} */}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </BoxWrapper>
  )

  const documentForm = (
    <BoxWrapper title="ข้อมูลรูปภาพ">
      <Row gutter={[16, 16]} justify="start">
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.images}
            listType="picture-card"
            name="images"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
        {/* <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.documents}
            listType="picture-card"
            name="documents"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col> */}
      </Row>
    </BoxWrapper>
  )

  const customerForm = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            placeHolder="ตัวอย่าง 0888888888"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppTextArea
            label="ที่อยู่"
            placeHolder="ที่อยู่"
            isRequired={isRequired('address')}
            isError={isError('address')}
            name="address"
            rows={2}
            value={formData.address}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_limit"
            label="วงเงินที่ลูกค้าต้องการ"
            isRequired={isRequired('amount_limit')}
            isError={isError('amount_limit')}
            value={formData.amount_limit}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_rate"
            label="อัตราผ่อนที่ชำระได้ /เดือน"
            isRequired={isRequired('amount_rate')}
            isError={isError('amount_rate')}
            value={formData.amount_rate}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_paint"
            label="สีรถ"
            isRequired={isRequired('car_paint')}
            isError={isError('car_paint')}
            value={formData.car_paint}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_registration"
            label="ทะเบียน"
            isRequired={isRequired('car_registration')}
            isError={isError('car_registration')}
            value={formData.car_registration}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="registration_province"
            label="จังหวัดของทะเบียน"
            isRequired={isRequired('registration_province')}
            isError={isError('registration_province')}
            value={formData.registration_province}
            onChange={onUpdateData}
            menu={getMenuSelect(provinceList, 'th', 'th')}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="financial_institution"
            label="สถาบันการเงินเดิม"
            isRequired={isRequired('financial_institution')}
            isError={isError('financial_institution')}
            value={formData.financial_institution}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="finance_account_closing"
            label="ยอดปิดบัญชีไฟแนนซ์เดิม"
            isRequired={isRequired('finance_account_closing')}
            isError={isError('finance_account_closing')}
            value={formData.finance_account_closing}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="note"
            label="หมายเหตุ"
            isRequired={isRequired('note')}
            isError={isError('note')}
            value={formData.note}
            onChange={onUpdateData}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <MyTitle>เพิ่มข้อมูลลูกค้า</MyTitle>
      </div>
      {getExternalLink()}
    </Space>
  )

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={FooterContent}
      width="85%"
      maskClosable={true}
    >
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {carForm}
        </Col>
        <Col span={24}> {customerForm}</Col>
        <Col md={24} xs={24}>
          {documentForm}
        </Col>
      </Row>
      {ConfirmApproveDailog}
    </DetailDrawer>
  )
}

const MyTitle = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const ExternalLink = styled.a`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const CarName = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.medium};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const CardPrice = styled(Card.Grid)`
  width: 50%;
  padding: 12px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  .ant-statistic-title {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  }

  &.actived {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    span,
    div {
      color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    }
  }

  @media only screen and (max-width: 720px) {
    width: 50%;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectActionEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    brandList: selectBrandList(state),
    modelList: selectModelList(state),
    yearList: selectYearList(state),
    provinceList: selectProvinceList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CreateCustomerTaskDetail)
