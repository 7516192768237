import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row, Space } from 'antd'
import { ActionButton, PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  getCustomerTaskStatus,
  isFormRequired,
  isFormError,
  toDateQuery,
  formatDateWithoutTime,
  formatYearold,
} from 'helpers/format'
import { DetailReq, UpdateReq, UpdateStatusReq } from 'Store/customer/types'
import { selectActionEvent, selectDetail, selectDetailEvent, selectTaskEvent } from 'Store/customer/selector'
import { clearEventAction } from 'Store/customer/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion } from 'Components/modal'
import { CustomerStatus, menuSex } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppDateItem, AppInput, AppSelect } from 'Components/form'
import { StatusTag } from 'Components/utilities'
import { getCustomertDetailAPI, updateCustomerTaskStatusAPI, updateCustomerTaskAPI } from 'Store/customer/api'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
}

const CustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    detail,
    detailEvent,
    loginData,
    userProfile,
  } = props

  const requireFeild = ['first_name', 'last_name', 'mobile']
  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const isTaskOpen = detail?.status === CustomerStatus.OPEN
  const isOwnTask = detail?.user_id === userProfile?.id
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && id) {
      onGetDetail()
    }
  }, [visible])

  useEffect(() => {
    if (id > 0) {
      const form = {
        birthday: detail?.birthday,
        email: detail?.email,
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        mobile: detail?.mobile,
        note: detail?.insure?.note,
        request_number: detail?.insure?.request_number,
        sex: detail?.sex,
      } as UpdateReq
      setFormData({ ...form })
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    dispatch(getCustomertDetailAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (): void => {
    const req: UpdateReq = {
      ...formData,
      birthday: formData.birthday ? toDateQuery(formData.birthday) : '',
      id: id,
    }
    dispatch(updateCustomerTaskAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: CustomerStatus, reason: string): void => {
    const req: UpdateStatusReq = {
      id,
      status,
      reason,
    }
    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || ''))
  }

  const onCancel = (reason: string): void => {
    onUpdateStatus(CustomerStatus.CANCEL, reason)
  }

  const onClose = (): void => {
    onUpdateStatus(CustomerStatus.CLOSE, '')
  }

  const onSave = () => {
    if (!validateForm()) return
    setEditMode(false)
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    onGetDetail()
    setRejectDialog(false)
    setApproveDialog(false)
    onSuccess()
  }

  const FooterContent = (
    <Space size="middle" align="baseline">
      {/* <PrimaryButton text="บันทึกข้อมูล" btnType="Cancel" onClick={onSave} /> */}
      <PrimaryButton
        text="ปิดเคส"
        btnType="Approve"
        icon={<CheckOutlined />}
        onClick={() => setApproveDialog(true)}
      />
      <PrimaryButton
        text="ยกเลิกเคส"
        icon={<CloseOutlined />}
        btnType="Reject"
        onClick={() => setRejectDialog(true)}
      />
    </Space>
  )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderStatus = (value: any) => {
    const result = getCustomerTaskStatus(value)
    return <StatusTag color={result.color} text={result.text} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ConfirmRejectDailog = (
    <ConfirmQuestion
      title="ยกเลิกเคส"
      placeholder="กรุณาระบุเหตุผลที่ยกเลิกเคส"
      confirmBtnTxt="ยืนยันยกเลิก"
      isShow={rejectDialog}
      handleOk={(remark: string) => onCancel(remark)}
      handleCancel={() => setRejectDialog(false)}
      loading={event.IsLoading}
    />
  )

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันปิดเคส"
      description="คุณแน่ใจที่จะปิดเคสนี้หรือไม่?"
      confirmBtnTxt="ยืนยัน"
      isShow={approveDialog}
      handleOk={onClose}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const customerFormLeft = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col md={24} xs={24}>
        <AppInput
          name="first_name"
          label="ชื่อ"
          isRequired={isRequired('first_name')}
          isError={isError('first_name')}
          value={formData.first_name}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="last_name"
          label="นามสกุล"
          isRequired={isRequired('last_name')}
          isError={isError('last_name')}
          value={formData.last_name}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppSelect
          name="sex"
          label="เพศ"
          isRequired={isRequired('sex')}
          isError={isError('sex')}
          value={formData.sex}
          onChange={onUpdateData}
          menu={menuSex}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppDateItem
          label="วันเดือนปีเกิด"
          placeHolder="วันเดือนปีเกิด"
          isRequired={isRequired('birthday')}
          isError={isError('birthday')}
          onChange={(date: string) => onUpdateData(date, 'birthday')}
          date={formData.birthday}
          allowClear={false}
          picker="date"
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="mobile"
          label="เบอร์โทรศัพท์"
          placeHolder="ตัวอย่าง 0888888888"
          isRequired={isRequired('mobile')}
          isError={isError('mobile')}
          value={formData.mobile}
          onChange={onUpdateData}
        />
      </Col>
    </Row>
  )

  const customerFormRight = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col md={24} xs={24}>
        <AppInput
          name="email"
          label="อีเมล"
          errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
          isRequired={isRequired('email')}
          isError={isError('email') || isError('email_format')}
          value={formData.email}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          label="หมายเหตุ"
          placeHolder="หมายเหตุ (ถ้ามี)"
          isRequired={isRequired('note')}
          isError={isError('note')}
          name="note"
          value={formData.note}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="request_number"
          label="เลขใบคำขอ"
          isRequired={isRequired('request_number')}
          isError={isError('request_number')}
          value={formData.request_number}
          onChange={onUpdateData}
        />
      </Col>
    </Row>
  )

  const taskInfo = (
    <BoxWrapper title="รายละเอียดข้อมูล">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')),
          renderInfo('สถานะ', renderStatus(detail?.status || '')),
        )}
        {renderInfoRow(
          renderInfo(
            'ผู้รับผิดชอบ',
            `${detail?.user?.profile?.first_name} ${detail?.user?.profile?.last_name} (ทีม ${detail?.agent?.agent_name})`,
          ),
          detail?.status === CustomerStatus.CANCEL ? renderInfo('เหตุผลที่ยกเลิก', detail?.reason || '') : '',
        )}
      </Row>
    </BoxWrapper>
  )

  const customerForm = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          {customerFormLeft}
        </Col>
        <Col md={12} xs={24}>
          {customerFormRight}
        </Col>
        <Col>
          <Space>
            <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={() => setEditMode(false)} />
            <PrimaryButton text="บันทึกข้อมูล" onClick={onSave} />
          </Space>
        </Col>
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => setEditMode(true)}
      />
    )
  }

  const customerInfo = (
    <BoxWrapper title="ข้อมูลลูกค้า" right={isTaskOpen && isOwnTask && editButton()}>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          {renderInfoRow(renderInfo('ชื่อ', detail?.first_name || ''), null)}
          {renderInfoRow(renderInfo('นามสกุล', detail?.last_name || ''), null)}
          {renderInfoRow(renderInfo('เพศ', detail?.sex || ''), null)}
          {renderInfoRow(
            renderInfo(
              'วันเดือนปีเกิด',
              detail?.birthday
                ? `${formatDateWithoutTime(detail?.birthday || '')} (อายุ ${formatYearold(
                    detail?.birthday || '',
                  )} ปี)`
                : '',
            ),
            null,
          )}
          {renderInfoRow(renderInfo('เบอร์โทรศัพท์', detail?.mobile || ''), null)}
        </Col>
        <Col md={12} xs={24}>
          {renderInfoRow(renderInfo('อีเมล', detail?.email || ''), null)}
          {renderInfoRow(renderInfo('หมายเหตุ', detail?.insure?.note || ''), null)}
          {renderInfoRow(renderInfo('หมายเลขคำขอ', detail?.insure?.request_number || ''), null)}
        </Col>
      </Row>
    </BoxWrapper>
  )

  const branchInfo = (
    <BoxWrapper title="การส่งข้อมูลไปยังสาขา">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ข้อมูลสาขา', detail?.insure?.branch_detail || ''),
          renderInfo('อีเมล', detail?.insure?.branch_email || ''),
        )}
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <Title>
          ชื่อลูกค้า : {detail?.first_name} {detail?.last_name}
        </Title>
      </div>
    </Space>
  )

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={isTaskOpen && !editMode && isOwnTask && FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        {detail?.insure?.is_branch && (
          <Col md={24} xs={24}>
            {branchInfo}
          </Col>
        )}
        {/* <Col span={24}>{isTaskOpen ? customerForm : customerInfo}</Col> */}
        <Col span={24}>{editMode ? customerForm : customerInfo}</Col>
      </Row>
      {ConfirmRejectDailog}
      {ConfirmApproveDailog}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const CreatedDate = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTaskDetail)
