import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { ListData, CountData } from './types'

export const selectListEvent = (state: RootState): Event => state.reportStandbyReducer.listEvent

export const selectList = (state: RootState): ListData => state.reportStandbyReducer.list

export const selectActionEvent = (state: RootState): Event => state.reportStandbyReducer.actionEvent

export const selectCount = (state: RootState): CountData => state.reportStandbyReducer.count
