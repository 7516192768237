import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { ListReq, ListRes, CountReq, CountRes } from './types'

export const getListAction = createAsyncAction(
  'STANDBY_REPORT/GET_STANDBY_REPORT_LIST_REQ',
  'STANDBY_REPORT/GET_STANDBY_REPORT_LIST_SUCCESS',
  'STANDBY_REPORT/GET_STANDBY_REPORT_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const countAction = createAsyncAction(
  'STANDBY_REPORT/GET_STANDBY_REPORT_COUNT_REQ',
  'STANDBY_REPORT/GET_STANDBY_REPORT_COUNT_SUCCESS',
  'STANDBY_REPORT/GET_STANDBY_REPORT_COUNT_FAILURE',
)<CountReq, CountRes, Error>()

export const clearEventAction = createStandardAction('STANDBY_REPORT/CLEAR_EVENT')<void>()
