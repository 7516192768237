import React, { useEffect } from 'react'
import { Card, Col, Empty, Row, Timeline } from 'antd'
import { DetailDrawer } from 'Components/common/MainLayout'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { LoadingView } from 'Components/modal'
import { MyTheme } from 'Components/common/Theme'
import { formatDateTime, formatDateWithoutTime, getBranchTaskStatus, numberWithComma } from 'helpers/format'
import Label from 'Components/text/Label'
import { getTaskLogsAPI } from 'Store/branch-task/api'
import { LogData, LogReq } from 'Store/branch-task/types'
import { selectLoginData } from 'Store/user/selector'
import { selectLogsData, selectLogsEvent } from 'Store/branch-task/selector'
import { InfoItem } from 'Components/text'

type Props = {
  isShow: boolean
  onClose: () => void
  id: number
} & ReduxProps

const Logs = (props: Props) => {
  const { isShow, onClose, id, loginData, list, event } = props

  const dispatch = useDispatch()

  const onGetDataList = (): void => {
    const req: LogReq = {
      id: id,
    }
    dispatch(getTaskLogsAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (isShow) {
      onGetDataList()
    }
  }, [isShow, id])

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const getGroupData = (data: LogData[]) => {
    const groups = data?.reduce((groups, item) => {
      const status = item?.status
      if (!groups[status]) {
        groups[status] = []
      }
      groups[status].unshift(item)
      return groups
    }, {})

    // Edit: to add it in the array format instead
    return Object.keys(groups).map(status => {
      return {
        status,
        datas: groups[status],
      }
    })
  }

  const getTimeLines = () => {
    const timeline = [] as any[]
    const data = getGroupData(list)
    timeline.push(
      <Timeline.Item key={'start'} dot={<StartDot style={{ backgroundColor: 'grey' }} />}>
        Branch: รอดำเนินการ
      </Timeline.Item>,
    )
    data.map((item, index) => {
      const status = getBranchTaskStatus(item.status)
      timeline.unshift(
        <Timeline.Item key={index} dot={<StartDot style={{ backgroundColor: status.color }} />}>
          Branch: {status.text}
          <br />
          {item.datas.map((log: LogData, i: number) => {
            const info = log.information || '{}'
            const jsonInfo = JSON.parse(info)
            return (
              <div key={i}>
                <LabelStyle>วัน/เวลา : {formatDateTime(log.created_at)}</LabelStyle> <br />
                <LabelStyle>โดย : {log.user_fullname}</LabelStyle>
                <MyCard style={{ width: '100%', margin: '10px 0' }}>
                  <div>รายละเอียด</div>
                  {renderInfo('เหตุผล', `${jsonInfo?.reason || '-'}`)}
                  {renderInfo('หมายเหตุ', `${jsonInfo?.note || '-'}`)}
                  {jsonInfo?.appointment_date &&
                    renderInfo(
                      'วันที่นัดหมายเข้าสาขา',
                      formatDateWithoutTime(jsonInfo?.appointment_date || ''),
                    )}
                  {jsonInfo?.approved_amount > 0 &&
                    renderInfo('อนุมัติวงเงิน', numberWithComma(jsonInfo?.approved_amount || 0) + ' บาท')}
                  {jsonInfo?.approved_date &&
                    renderInfo('วันที่อนุมัติ', formatDateWithoutTime(jsonInfo?.approved_date || ''))}
                </MyCard>
              </div>
            )
          })}
        </Timeline.Item>,
      )
    })
    return timeline
  }

  return (
    <DetailDrawer
      title={'ประวัติสาขาอัปเดตข้อมูลเคส'}
      width={'50%'}
      onCloseDrawer={onClose}
      visible={isShow}
      footer={null}
      maskClosable={true}
    >
      <LoadingView isShow={event.IsLoading} />
      <Row>
        <Col span={24}>
          {list && list.length > 0 ? (
            <Timeline>{getTimeLines()}</Timeline>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Col>
      </Row>
    </DetailDrawer>
  )
}

const LabelStyle = styled(Label)`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const MyCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
  }
`

const StartDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectLogsEvent(state),
    list: selectLogsData(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Logs)
